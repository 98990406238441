import {useState, type FC, useEffect, useRef, Dispatch, SetStateAction} from 'react'

import classes from './BulkRunForm.module.scss'
import SelectArrowIcon from '../../icons/modalform/SelectArrowIcon'
import langsData from '../../../data/universal-data/langsData'
import AWS from 'aws-sdk'
import {IColumnsOrder, IWorkflowFormInputs} from '../../../../types/types'
import useChatGPTRequest from '../../../hooks/useChatGPTRequest'
import useRelevanceBulkRequest from '../../../hooks/useRelevanceBulkRequest'
import {showLimitError} from '../../../../utils/showLimitError'
import useDalleRequest from '../../../hooks/useDalleRequest'
import imageFormData from '../../../data/image-generation/imageFormData'
import ReactivateIcon from '../../icons/workflow/ReactivateIcon'
import BulkRunTableHeader from './BulkRunTableHeader'
import CopyIcon from '../../icons/chat/CopyIcon'
import EditIcon from '../../icons/chat/EditIcon'
import RefreshIcon from '../../icons/chat/RefreshIcon'
import UniversalHiddenScreen from '../hidden-screen/UniversalHiddenScreen'
import BulkRunEditor from './BulkRunEditor'
import {replace} from 'formik'
import {toast} from 'react-toastify'
import axios from 'axios'
import LeadFinderBulkRunTableHeader from './LeadFinderBulkRunTableHeader'
import {useNavigate} from 'react-router-dom'

interface LeadFinderBulkRunFormProps {
  rowIndex: any
  fetchData: any
  mappingInputs: any
  templateItem: any
  triggerRegenerateText?: string | number
  triggerRegenerateImg?: string | number
  csvInputs?: [] | number
  columnsOrder: number[]
  inputsNames: string[]
  index: number
  setActiveIndex: Dispatch<SetStateAction<number | null>>
  activeIndex: number | null
}

const LeadFinderBulkRunForm: FC<LeadFinderBulkRunFormProps> = ({
  rowIndex,
  fetchData,
  mappingInputs,
  templateItem,
  triggerRegenerateText = '',
  triggerRegenerateImg = '',
  csvInputs = [],
  columnsOrder = [],
  inputsNames = [],
  activeIndex,
  setActiveIndex,
  index,
}) => {
  const arrowColor = '#ffffff'

  const {
    requiredQuestions,
    additionalQuestions,
    optionalSelect,
    axiosURL,
    axiosHeader,
    relevanceRequest,
    easyRequest,
    limits,
    imgGenerate,
    formType,
  } = templateItem

  class FormInputs implements IWorkflowFormInputs {
    requiredQuestion1 = ''
    requiredQuestion2 = ''
    requiredQuestion3 = ''
    requiredQuestion4 = ''
    requiredQuestion5 = ''
    additionalQuestion1 = ''
    additionalQuestion2 = ''
    additionalQuestion3 = ''
    optionalSelect1 = optionalSelect[0]?.options[0] || ''
    optionalSelect2 = optionalSelect[1]?.options[0] || ''
    language = langsData[0]
    variations = variationsData[0]
    media = ''
    // imagesInclude = false
  }

  const {axiosURL: axiosURLDalle, requiredQuestions: requiredQuestionsDalle} = imageFormData

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  })

  const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME
  const REGION = process.env.REACT_APP_AWS_REGION
  const myBucket = new AWS.S3({
    params: {Bucket: S3_BUCKET},
    region: REGION,
  })

  const variationsData = [1, 2, 3, 4]
  const socialMediaData = ['Instagram', 'LinkedIn', 'Twitter', 'Facebook']
  const [currentAnswer, setCurrentAnswer] = useState('...')
  const [isLoadingImg, setIsLoadingImg] = useState(false)
  const [isLoadingText, setIsLoadingText] = useState(false)
  const [disabledButtons, setDisabledButtons] = useState(false)
  const [previewTemplate, setPreviewTemplate] = useState('')
  const [images, setImages] = useState<string[]>([])
  const [message, setMessage] = useState<string>('')
  const [isVisibleMessage, setIsVisibleMessage] = useState(false)
  const [visibleBackground, setVisibleBackground] = useState<boolean>(false)
  const [inputs, setInputs] = useState<IWorkflowFormInputs>(new FormInputs())
  const [isVisibleAdditionalQuestion, setIsVisibleAdditionalQuestion] = useState<boolean>(false)
  const [uuid, setUuid] = useState<string | undefined>(undefined)
  const [error, setError] = useState('')
  const [currentAnswerObj, setCurrentAnswerObj] = useState<any>({})
  const myRef = useRef<HTMLFormElement | null>(null)
  const navigate = useNavigate()

  const urlParams = new URLSearchParams(window.location.search)
  const bulkRunUuidParam = urlParams.get('uuid')
  const bulkRunHasDataParam = urlParams.get('has-data')

  useEffect(() => {
    if (bulkRunUuidParam) {
      setUuid(bulkRunUuidParam)
    }

    if (mappingInputs && mappingInputs.requiredQuestion1) {
      setInputs(mappingInputs)
    }

    if (fetchData && bulkRunHasDataParam) {
      setInputs(fetchData.questions[0])
      setCurrentAnswer(fetchData.current_answer)
    }
  }, [mappingInputs, bulkRunUuidParam, fetchData, bulkRunHasDataParam])

  useEffect(() => {
    const updatedAnswerObj =
      currentAnswer === '...' || currentAnswer === "Klevere's AI is working on your output..."
        ? {}
        : currentAnswer
    setCurrentAnswerObj(updatedAnswerObj)
  }, [currentAnswer])

  const handleSetUuid = (uuid: string | undefined) => {
    setUuid(uuid)
    navigate(`?uuid=${uuid}${bulkRunHasDataParam ? '&has-data=true' : ''}`)
  }

  const handleSaveActivity = async (currentAnswer: string, extraData?: any) => {
    const response = axios
      .post(`${process.env.REACT_APP_API_URL}/bulk-run`, {
        rowIndex: ++rowIndex,
        uuid: bulkRunUuidParam,
        title: templateItem.title,
        url: templateItem.url,
        header: templateItem.header,
        data: [
          {
            questions: [{...inputs}],
            current_answer: currentAnswer,
            extraData,
          },
        ],

        current_answer: currentAnswer,
      })
      .then((res) => {
        handleSetUuid(res.data.uuid)
      })
      .catch((err) => console.log(err))
  }

  const {handleForm: handleChatGPTForm} = useChatGPTRequest({
    myRef,
    axiosURL,
    axiosHeader,
    requiredQuestions,
    additionalQuestions,
    optionalSelect,
    inputs,
    setCurrentAnswer,
    setError,
    setIsLoading: setIsLoadingText,
    activeIndex,
    setActiveIndex,
    handleSaveActivity,
  })

  const {handleForm: handleRelevanceForm} = useRelevanceBulkRequest({
    myRef,
    setCurrentAnswer,
    requiredQuestions,
    additionalQuestions,
    axiosURL,
    easyRequest,
    inputs,
    setError,
    setIsLoading: setIsLoadingText,
  })

  const {axiosRequest: dalleRequest} = useDalleRequest({
    axiosURL: axiosURLDalle,
    requiredQuestions: requiredQuestionsDalle,
    inputs,
    setImages,
    setIsLoading: setIsLoadingImg,
    setMessage,
    handleMessageVisiability,
    setError,
  })

  function handleMessageVisiability() {
    setIsVisibleMessage(true)

    return setTimeout(() => {
      setIsVisibleMessage(false)
    }, 2000)
  }

  const generateText = (e: React.FormEvent | any) => {
    relevanceRequest ? handleRelevanceForm(e) : handleChatGPTForm(e)
  }

  const generateImg = () => {
    dalleRequest()
  }

  const handleForm = async (e: React.FormEvent | any, regenarateImg = false) => {
    const form = myRef.current
    if (form && form.checkValidity()) {
      setDisabledButtons(true)
      inputs?.media && setPreviewTemplate(inputs.media)

      if (e?.type === 'submit') {
        setImages([])
        setCurrentAnswer('')
        generateText(e)
        if (inputs.imagesInclude) {
          generateImg()
        }
      }
      if (e?.type !== 'submit') {
        regenarateImg ? generateImg() : generateText(e)
      }
    }
  }

  const handleInputs = (e: any) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    const name = e.target.name

    setInputs((prev: any) => ({...prev, [name]: value}))
  }

  const handleVisibleAdditQuest = () => {
    setIsVisibleAdditionalQuestion((prev) => !prev)
  }

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name
    if (e.target.files !== null && e.target.files.length > 0) {
      const file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)

      axios
        .post(`${process.env.REACT_APP_API_URL}/aws/upload`, formData)
        .then((res: any) => {
          if (res.data.success === true) {
            const fileLink = res.data.location
            setInputs((prev: any) => ({...prev, [name]: fileLink}))
          }
        })
        .catch((e: any) => {
          if (e.response.data.message) {
            toast.error('Please provide a valid file')
          }
        })
    }
  }

  const handleCopyAnswer = async (dataToCopy: any) => {
    try {
      if (dataToCopy === '...') {
        toast.warning('No content to copy!')
        return
      }
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(dataToCopy)
      } else {
        const textarea = document.createElement('textarea')
        textarea.value = dataToCopy
        document.body.appendChild(textarea)
        textarea.select()
        document.execCommand('copy')
        document.body.removeChild(textarea)
      }
      toast.success('Content copied successfully')
    } catch (error) {
      console.error('Unable to copy to clipboard', error)
    }
  }

  const handleVisibility = () => {
    setVisibleBackground((prev) => !prev)
  }

  const requiredQuestionsBlock = requiredQuestions.map((item: any, index: number) => {
    const {id, title, type, placeholder, tooltipText, tooltipAnchor} = item
    const name = `requiredQuestion${index + 1}` as keyof typeof inputs

    return type === 'input' ? (
      <td key={id}>
        <textarea
          name={name}
          value={inputs[name]}
          onChange={handleInputs}
          required={true}
          placeholder='Enter'
        />
      </td>
    ) : (
      <td key={id}>
        <label className={classes.fileInput}>
          {inputs[name] ? '+ Change File' : placeholder}
          <input type='file' name={name} required={true} onChange={uploadFile} />
        </label>
      </td>
    )
  })

  const additionalQuestionsBlock = additionalQuestions.map((item: any, index: number) => {
    const {id, title, type, placeholder, tooltipText, tooltipAnchor} = item
    const name = `additionalQuestion${index + 1}` as keyof typeof inputs
    return type === 'input' ? (
      <td key={id}>
        <textarea name={name} value={inputs[name]} onChange={handleInputs} placeholder='Enter' />
      </td>
    ) : (
      <td key={id}>
        <label className={classes.fileInput}>
          {inputs[name] ? '+ Change File' : placeholder}
          <input type='file' name={name} required={true} onChange={uploadFile} />
        </label>
      </td>
    )
  })

  const optionalSelectBlock = optionalSelect.map((item: any, index: number) => {
    const {id, title, options} = item
    const name = `optionalSelect${index + 1}` as keyof typeof inputs
    const optionsBlock = options.map((unit: any, index: any) => (
      <option key={index} value={unit}>
        {unit}
      </option>
    ))

    return (
      <td key={id}>
        <div className={classes.selectBlock}>
          <select name={name} value={inputs[name]} onChange={handleInputs}>
            {optionsBlock}
          </select>
          <div className={classes.selectBlock__imgBlock}>
            <SelectArrowIcon className={classes.selectBlock__imgBlock__svg} />
          </div>
        </div>
      </td>
    )
  })

  const languages = [
    'English',
    'Arabic',
    'Danish',
    'Dutch',
    'French',
    'German',
    'Greek',
    'Hindi',
    'Indonesian',
    'Italian',
    'Malay',
    'Norwegian',
    'Portuguese',
    'Russian',
    'Spanish',
    'Swedish',
    'Ukrainian',
  ]

  const tones = ['Conversational', 'Enthusiastic', 'Humorous', 'Professional']

  const languageOptionsBlock = langsData.map((item, index) => {
    return (
      <option key={index} value={item}>
        {item}
      </option>
    )
  })

  const variationsOptionsBlock = variationsData.map((item, index) => {
    return (
      <option key={index} value={item}>
        {item}
      </option>
    )
  })

  const mediaOptionsBlock = socialMediaData.map((item, index) => {
    return (
      <option key={index} value={item}>
        {item}
      </option>
    )
  })

  // const imagesBlock = images.map((item: string, index: number) => {
  //   return (
  //     <div key={index} className={classes.images__imgBlock}>
  //       <img src={item} alt='generated' />
  //     </div>
  //   )
  // })

  useEffect(() => {
    const indexOfLanguage = inputsNames.indexOf('language')
    const languageName = languages[columnsOrder[indexOfLanguage]]

    const index = langsData.indexOf(languageName)
    if (index !== -1) {
      langsData.splice(index, 1)
      langsData.unshift(languageName)
    }
  }, [csvInputs])

  useEffect(() => {
    const indexOfTone = inputsNames.indexOf('optionalSelect1')

    if (indexOfTone !== -1) {
      const tone = tones[columnsOrder[indexOfTone]]

      if (tone) {
        const toneLowerCase = tone.toLocaleLowerCase()
        const index = optionalSelect[0].options.indexOf(toneLowerCase)

        if (index !== -1) {
          optionalSelect[0].options.splice(index, 1)
          optionalSelect[0].options.unshift(toneLowerCase)
        }
      }
    }
  }, [csvInputs])

  useEffect(() => {
    if (activeIndex === index) {
      handleForm(true)
    }
  }, [activeIndex])

  useEffect(() => {
    if (triggerRegenerateImg) {
      handleForm(true, true)
    }
  }, [triggerRegenerateImg])

  useEffect(() => {
    if (!isLoadingImg && !isLoadingText) {
      setDisabledButtons(false)
    }
  }, [isLoadingImg, isLoadingText])

  useEffect(() => {
    /* fill form inputs if  it has outer data (csvInputs) from csv file*/
    if (typeof csvInputs !== 'number') {
      const newInputs = new FormInputs()
      const keysArr = Object.keys(newInputs)
      inputsNames.forEach((item: string, index: number) => {
        if (keysArr.includes(item)) {
          const inputName = item as keyof FormInputs

          const fieldFromCSVIndex = columnsOrder[index]
          const value = csvInputs[fieldFromCSVIndex]
          console.log('for ' + inputName + ' is ' + value)
          /*TODO implement checking values for selects */
          if (inputName === 'variations' && typeof value !== 'number') {
            newInputs[inputName] = +value
          }
          if (inputName !== 'variations' && typeof value === 'string') {
            newInputs[inputName] = value
          }
        }
      })
      const indexOfResult = inputsNames.length
      const fieldResultIndex = columnsOrder[indexOfResult]
      let resultValue = '...'
      /* Result will be coming from the backend but why from csv. */
      // if (typeof csvInputs[fieldResultIndex] === 'string') {
      //   resultValue = csvInputs[fieldResultIndex]
      // }

      setCurrentAnswer(resultValue)
      setInputs(newInputs)
    }
  }, [csvInputs])

  return (
    <>
      <form ref={myRef} className={classes.form} onSubmit={handleForm}>
        <table id='_table' style={{tableLayout: 'fixed', width: '100%'}}>
          <LeadFinderBulkRunTableHeader templateItem={templateItem} />
          <tbody>
            <tr>
              <td className={classes.generateButton}>
                <button disabled={disabledButtons} title='Generate'>
                  <RefreshIcon
                    className={`${classes.generateButton__svg} ${
                      isLoadingText ? classes.animated : undefined
                    }`}
                    color={arrowColor}
                  />
                </button>
              </td>
              {requiredQuestionsBlock}

              <td className={classes.result__td} style={{verticalAlign: 'middle'}}>
                <span style={{paddingTop: '1.25rem'}}>
                  {' '}
                  {currentAnswerObj.first_name ? currentAnswerObj.first_name : '...'}
                </span>
              </td>

              <td className={classes.result__td} style={{verticalAlign: 'middle'}}>
                <span style={{paddingTop: '1.25rem'}}>
                  {currentAnswerObj.last_name ? currentAnswerObj.last_name : '...'}
                </span>
              </td>

              <td className={classes.result__td} style={{verticalAlign: 'middle'}}>
                <span style={{paddingTop: '1.25rem'}}>
                  {currentAnswerObj.email ? currentAnswerObj.email : '...'}
                </span>
              </td>

              <td className={classes.result__td} style={{verticalAlign: 'middle'}}>
              <span style={{paddingTop: '1.25rem'}}>
                  {currentAnswerObj.email_deliverability ? currentAnswerObj.email_deliverability : '...'}
                </span>
              </td>

              <td className={classes.result__td} style={{verticalAlign: 'middle'}}>

              <span style={{paddingTop: '1.25rem'}}>
                  {currentAnswerObj.confidence ? currentAnswerObj.confidence : '...'}
                </span>
              </td>

              <td className={classes.result__td} style={{verticalAlign: 'middle'}}>
                <span style={{paddingTop: '1.25rem'}}>
                  {currentAnswerObj.company_name ? currentAnswerObj.company_name : '...'}
                </span>
              </td>

              <td className={classes.result__td} style={{verticalAlign: 'middle'}}>
                <span style={{paddingTop: '1.25rem'}}>
                  {currentAnswerObj.company_size ? currentAnswerObj.company_size : '...'}
                </span>
              </td>

              <td className={classes.result__td} style={{verticalAlign: 'middle'}}>
                <span style={{paddingTop: '1.25rem'}}>
                  {currentAnswerObj.industry ? currentAnswerObj.industry : '...'}
                </span>
              </td>

              <td className={classes.result__td} style={{verticalAlign: 'middle'}}>
                <span style={{paddingTop: '1.25rem'}}>
                  {currentAnswerObj.company_website ? currentAnswerObj.company_website : '...'}
                </span>
              </td>

              <td className={classes.result__td} style={{verticalAlign: 'middle'}}>
                <span style={{paddingTop: '1.25rem'}}>
                  {currentAnswerObj.company_location ? currentAnswerObj.company_location : '...'}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </>
  )
}

export default LeadFinderBulkRunForm
