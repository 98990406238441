// src/store/bulk-run/bulkRunSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';

interface BulkRunState {
  selectAllCheckbox: boolean; 
  numberOfRows: number;

  sortingDirection:string
}

const initialState: BulkRunState = {
  selectAllCheckbox: false,  
  numberOfRows:0,
  sortingDirection: ''
};

const bulkRunSlice = createSlice({
  name: 'bulkRun',
  initialState,
  reducers: {

    setSortingDirection: (state, action) => {
      state.sortingDirection = action.payload;
    },

    setNumberOfRowsCheckbox: (state, action) => {
        state.selectAllCheckbox = action.payload;
      },

    setSelectAllCheckbox: (state, action) => {
      state.selectAllCheckbox = action.payload;
    },

    toggleSelectAllCheckbox: (state) => {
      state.selectAllCheckbox = !state.selectAllCheckbox;
    },
  },
});

export const { setSelectAllCheckbox, toggleSelectAllCheckbox,setNumberOfRowsCheckbox,setSortingDirection } = bulkRunSlice.actions;
export default bulkRunSlice.reducer;

export const selectSelectAllCheckbox = (state: { bulkRun: BulkRunState }) => state.bulkRun.selectAllCheckbox;
export const selectFilter = (state: { bulkRun: BulkRunState }) => state.bulkRun.sortingDirection;