import {useEffect, useState, type FC} from 'react'
import {Button, Form, Table} from 'react-bootstrap'
import {GiHamburgerMenu} from 'react-icons/gi'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useAuth} from '../../../../app/modules/auth'
import {
  useDeleteActivityMutation,
  useGetActivitiesQuery,
} from '../../../../services/activityServiceApi'
import DelIcon from '../../icons/homepage/DelIcon'
import EditIcon from '../../icons/homepage/EditIcon'
import ConfirmationModal from '../../modal-page/ConfirmationModal'
import classes from '../RecentActivity.module.scss'
import {GlobalErrorMessagesEnum} from '../../../../types/types'
import {useDeleteMutation, useFindAllQuery} from '../../../../services/bulkRunService'
import {getLastOpenedFormat} from '../../../../utils/getLastOpenedFormat'

interface BulkRunProps {}

const BulkRunComponent: FC<BulkRunProps> = () => {
  const [page, setPage] = useState(1)
  const [switchBulkRun, setSwitchBulkRun] = useState(false)
  const [activityToDelete, setActivityToDelete] = useState<string>('')
  const [deleteActivity] = useDeleteMutation()
  const {currentUser, toggleSidebar} = useAuth()

  const {
    data: {activities = [], hasMore = false} = {},
    isLoading,
    refetch,
  } = useFindAllQuery({
    user_id: currentUser?.id,
    page,
  })

  const navigate = useNavigate()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const bulkRunParam = urlParams.get('bulk-run')

    if (bulkRunParam === 'true') {
      setSwitchBulkRun(true)
    } else {
      setSwitchBulkRun(false)
    }
  }, [])

  useEffect(() => {
    if (switchBulkRun) {
    }
  }, [switchBulkRun])

  const handleSwitchBulkRun = () => {
    setSwitchBulkRun(!switchBulkRun)
    navigate(`?bulk-run=${!switchBulkRun}`)
  }

  if (isLoading) {
    return <div>Loading...</div>
  }

  const handleDelete = (uuid: string) => {
    console.trace('uuid', uuid)
    deleteActivity(uuid)
      .unwrap()
      .then(() => {
        toast.success('Activity successfully deleted')
      })
      .catch(() => {
        toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
      })
      .finally(() => {
        refetch()
      })
  }
  const handleCancel = () => {
    setActivityToDelete('')
  }
  const handleConfirm = () => {
    handleDelete(activityToDelete)
    setActivityToDelete('')
  }

  const generateBulkRunRoute = ({ header, url, uuid }: { header: string; url: string; uuid: string }) => {
    const formattedHeader = header.replace(/\s+/g, '-').toLowerCase();
    const isEmailFinderWorkflow = url === 'professional-email-finder';
    const bulkRunPath = isEmailFinderWorkflow ? '' : '/bulk-run';
  
    return `/${formattedHeader}/${url}${bulkRunPath}?uuid=${uuid}&has-data=true`;
  };
  

  const tableRows = activities.map(
    (item: {
      id: number
      header: string
      title: string
      uuid: string
      updated_at: string
      url: string
      data: any
      questions: {
        requiredQuestion1: string
      }
    }) => {

      return (
        <tr
          key={item.id}
          style={{
            borderBottom: '1px solid #f1f1f1',
            height: '50px',
          }}
        >
          <td
            style={{
              width: '250px',
            }}
          >
            {item.data[0].questions[0].requiredQuestion1.slice(0, 20)}{' '}
            {item.data[0].questions[0].requiredQuestion1.length > 20 ? '...' : ''}
          </td>
          <td
            style={{
              width: '350px',
              textAlign: 'start',
            }}
          >
            {item.title}
          </td>
          <td>{item.header}</td>
          <td
            style={{
              width: '250px',
              textAlign: 'start',
            }}
          >
            {getLastOpenedFormat(item.updated_at)}
          </td>
          <td
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '22px',
            }}
          >
            <Link
              to={generateBulkRunRoute(item)}
              title='Go to your activity'
            >
              <EditIcon className={classes.svg} />
            </Link>

            <p
              style={{
                cursor: 'pointer',
              }}
              onClick={() => setActivityToDelete(item.uuid)}
            >
              <DelIcon className={classes.svg} />
            </p>
          </td>
        </tr>
      )
    }
  )
  const handlePagination = (action: string) => {
    if (action === 'next') {
      setPage((prev) => prev + 1)
    } else {
      setPage((prev) => prev - 1)
    }
  }

  return (
    <div className='w-100'>
      <Table
        responsive
        style={{
          borderCollapse: 'collapse',
          borderSpacing: '0',
        }}
      >
        <thead>
          <tr>
            <th>Name</th>
            <th>Workflow</th>
            <th>Category</th>
            <th>Last Opened</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </Table>
      <tfoot
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '22px',
          width: '100%',
        }}
        className='mt-3 mt-md-0'
      >
        {(activities.length > 0 || hasMore || page > 1) && (
          <tr>
            <td colSpan={7}>
              <nav
                className='w-100 bg-light  d-flex justify-content-center p-3'
                style={{
                  borderRadius: '1rem',
                }}
              >
                <ul className='pagination pagination-lg'>
                  <li className='page-item' onClick={() => handlePagination('prev')}>
                    <button className='page-link' disabled={page === 1}>
                      <span aria-hidden='true'>&laquo;</span>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button className='page-link'>{page}</button>
                  </li>
                  <li className='page-item' onClick={() => handlePagination('next')}>
                    <button className='page-link' disabled={!hasMore}>
                      <span aria-hidden='true'>&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
        )}
      </tfoot>
      <ConfirmationModal
        header='Warning'
        message='Are you sure you want to delete this activity?'
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        show={activityToDelete !== '' ? true : false}
      />
    </div>
  )
}

export default BulkRunComponent
