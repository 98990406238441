import {Button, ButtonGroup, CloseButton, Dropdown, DropdownButton, Form} from 'react-bootstrap'
import EditIcon from '../../icons/images/image-generation-form/EditIcon'
import LockIcon from '../../icons/images/image-generation-form/LockIcon'
import UploadIcon from '../../icons/images/image-generation-form/UploadIcon'
import CloseIcon from '../../icons/images/image-generation-form/CloseIcon'
import classes from './ImageGenerationForm.module.scss'
import ChevronDownIcon from '../../icons/images/image-generation-form/ChevronDownIcon'
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import InfoIcon from '../../icons/images/image-generation-form/InfoIcon'
import {forwardRef, useEffect, useRef, useState} from 'react'
import {useGenerateImageMutation} from '../../../../services/imageGenerationService'
import {toast} from 'react-toastify'
import {
  aestheticOptions,
  moodLightingOptions,
  styleOptions,
  TypeEnum,
  typeOptions,
} from '../data/types'
import {GlobalErrorMessagesEnum} from '../../../../types/types'
import {lowerCase, startCase} from 'lodash'
import CombinedFormClasses from '../../universal-components/combined-form/CombinedForm.module.scss'
interface FormState {
  prompt: string
  magicPrompt: string
  aspectRatio: string
  visibility: string
  style: any
  aesthetic: any
  mood_and_lighting: any
  type: string
}

interface ImageDescToggleProps {
  onClick: Function
  prompt: string
}

const ImageDescToggle = forwardRef(({onClick, prompt}: ImageDescToggleProps, ref: any) => (
  <Form.Group
    className='d-flex gap-3'
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    <Form.Control
      type='text'
      className={classes.imageDescToggle}
      placeholder='Description'
      readOnly
      value={prompt}
    />
    <Button
      className={`px-md-5 ${classes.btn__submit} universalButton d-flex justify-content-center align-items-center`}
      style={{width: 'unset'}}
    >
      Generate
    </Button>
  </Form.Group>
))

interface ImageGenerationFormExpandedProps {
  formState: FormState
  setFormState: (state: FormState) => void
  imageDescTextareaRef: any
  setIsExtendedVisible: Function
  'aria-labelledby'?: any
  className?: any
  style?: any
  isLoading: boolean
}

const ImageGenerationFormExpanded = forwardRef(
  (
    {
      formState,
      setFormState,
      imageDescTextareaRef,
      setIsExtendedVisible,
      'aria-labelledby': labeledBy,
      className,
      style,
      isLoading,
    }: ImageGenerationFormExpandedProps,
    ref: any
  ) => {
    const updateFormState = (key: keyof FormState, value: string) => {
      setFormState({
        ...formState,
        [key]: value,
      })
    }

    return (
      <div
        className={`rounded-4 p-0 border w-100 ${className}`}
        ref={ref}
        style={style}
        aria-label={labeledBy}
      >
        <div className='row px-3 py-2'>
          <div className='col-12 col-md-9 border-end py-3'>
            <textarea
              placeholder='Describe what you want to see (max: 2000 characters)'
              className={classes.textarea}
              ref={imageDescTextareaRef}
              value={formState.prompt}
              maxLength={2000}
              onChange={(e) => updateFormState('prompt', e.target.value)}
            ></textarea>
          </div>
          <div className='col-12 col-md-3 py-3'>
            <div className='d-flex flex-column'>
              <div className='d-flex justify-content-end align-items-center'>
                <CloseButton title='Close' onClick={() => setIsExtendedVisible(false)} />
              </div>
            </div>

            <div className='d-flex flex-column gap-3 mt-3'>
              <div className='d-flex align-items-center'>
                <div className='d-flex' style={{minWidth: '124px'}}>
                  <h3 className={`d-flex align-items-center gap-1 ${classes.form__label__title}`}>
                    Magic Prompt
                    {/* <InfoIcon /> */}
                  </h3>
                </div>
                <ButtonGroup className={` flex-grow-1`}>
                  <Button
                    variant='light'
                    className={`${
                      formState.magicPrompt === 'AUTO' ? classes.btn__active : classes.btn
                    } ${classes.form__label__title}`}
                    onClick={() => updateFormState('magicPrompt', 'AUTO')}
                  >
                    Auto
                  </Button>
                  <Button
                    variant='light'
                    className={`${
                      formState.magicPrompt === 'ON' ? classes.btn__active : classes.btn
                    } ${classes.form__label__title}`}
                    onClick={() => updateFormState('magicPrompt', 'ON')}
                  >
                    On
                  </Button>
                  <Button
                    variant='light'
                    className={`${
                      formState.magicPrompt === 'OFF' ? classes.btn__active : classes.btn
                    } ${classes.form__label__title}`}
                    onClick={() => updateFormState('magicPrompt', 'OFF')}
                  >
                    Off
                  </Button>
                </ButtonGroup>
              </div>
              <div className='d-flex align-items-center'>
                <div className='d-flex' style={{minWidth: '124px'}}>
                  <h3 className={classes.form__label__title}>Aspect Ratio</h3>
                </div>
                <ButtonGroup className={`${classes.form__label__title} flex-grow-1`}>
                  <Button
                    variant='light'
                    className={`${
                      formState.aspectRatio === 'ASPECT_9_16' ? classes.btn__active : classes.btn
                    } ${classes.form__label__title}`}
                    onClick={() => updateFormState('aspectRatio', 'ASPECT_9_16')}
                  >
                    9:16
                  </Button>
                  <Button
                    variant='light'
                    className={`${
                      formState.aspectRatio === 'ASPECT_1_1' ? classes.btn__active : classes.btn
                    } ${classes.form__label__title}`}
                    onClick={() => updateFormState('aspectRatio', 'ASPECT_1_1')}
                  >
                    1:1
                  </Button>
                  <Button
                    variant='light'
                    className={`${
                      formState.aspectRatio === 'ASPECT_16_9' ? classes.btn__active : classes.btn
                    } ${classes.form__label__title}`}
                    onClick={() => updateFormState('aspectRatio', 'ASPECT_16_9')}
                  >
                    16:9
                  </Button>
                </ButtonGroup>
              </div>

              <div className='d-flex align-items-center'>
                <div className='d-flex' style={{minWidth: '124px'}}>
                  <h3 className={classes.form__label__title}>Style</h3>
                </div>
                <Dropdown
                  className={`${classes.form__label__title} w-100`}
                  onSelect={(k) => k && updateFormState('type', k)}
                >
                  <DropdownToggle variant='light' className={`w-100 ${classes.btn__dropdown}`}>
                    <div className='d-flex justify-content-center align-items-center position-relative'>
                      <p
                        className={`${classes.form__label__title} mb-0`}
                        style={{textTransform: 'capitalize'}}
                      >
                        {formState.type === TypeEnum.RENDER_3D
                          ? '3D Render'
                          : startCase(lowerCase(formState.type))}
                      </p>
                      <ChevronDownIcon height='24' width='24' className='position-absolute end-0' />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className={`${classes.form__label__title} w-100`}>
                    {typeOptions.map((type: string) => (
                      <Dropdown.Item key={type} eventKey={type}>
                        {type === TypeEnum.RENDER_3D ? '3D Render' : startCase(lowerCase(type))}
                      </Dropdown.Item>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>

              {/* <div className='d-flex align-items-center'>
                <div className='d-flex' style={{minWidth: '124px'}}>
                  <h3 className={classes.form__label__title}>Aesthetic</h3>
                </div>
                <Dropdown className={`${classes.form__label__title} w-100`} onSelect={(k) => k && updateFormState('aesthetic', k)}>
                  <DropdownToggle variant='light' className={`w-100 ${classes.btn__dropdown}`}>
                    <div className='d-flex justify-content-center'>
                      <p className={`${classes.form__label__title} flex-grow-1`}  style={{textTransform: 'capitalize'}}>
                        {startCase(lowerCase(formState.aesthetic))}
                      </p>
                      <ChevronDownIcon />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className='w-100'>
                    {aestheticOptions.map((type: string) => (
                      <Dropdown.Item key={type} eventKey={type}>
                        {type}
                      </Dropdown.Item>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div> */}
              {/* <div className='d-flex align-items-center'>
                <div className='d-flex' style={{minWidth: '124px'}}>
                  <h3 className={classes.form__label__title}>Mood & Lighting</h3>
                </div>
                <Dropdown
                className={`${classes.form__label__title} w-100`}
                  onSelect={(k) => k && updateFormState('mood_and_lighting', k)}
                >
                  <DropdownToggle variant='light' className={`w-100 ${classes.btn__dropdown}`}>
                    <div className='d-flex justify-content-center'>
                      <p className={`${classes.form__label__title} flex-grow-1`} style={{textTransform: 'capitalize'}}>
                        {startCase(lowerCase(formState.mood_and_lighting))}
                      </p>
                      <ChevronDownIcon />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className={`${classes.form__label__title} w-100`} >
                    {moodLightingOptions.map((type: string) => (
                      <Dropdown.Item key={type} eventKey={type}>
                        {type}
                      </Dropdown.Item>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div> */}
              {/* <div className='d-flex align-items-center'>
                <div className='d-flex' style={{minWidth: '124px'}}>
                  <p>Style</p>
                </div>
                <Dropdown className='w-100' onSelect={(k) => k && updateFormState('style', k)}>
                  <DropdownToggle variant='light' className={`w-100 ${classes.btn__dropdown}`}>
                    <div className='d-flex justify-content-center'>
                      <p className='flex-grow-1' style={{textTransform: 'capitalize'}}>
                        {formState.style}
                      </p>
                      <ChevronDownIcon />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu className='w-100'>
                    {styleOptions.map((type: string) => (
                      <Dropdown.Item key={type} eventKey={type}>
                        {type}
                      </Dropdown.Item>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div> */}
            </div>
            <div className='d-flex justify-content-end align-items-center mt-5 gap-3'>
              <Button
                className={`w-100 ${classes.btn__submit} universalButton d-flex justify-content-center align-items-center`}
                type='submit'
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className='d-flex align-items-center'>
                    <span className='spinner-border spinner-border-sm me-2'></span>
                    Generating...
                  </span>
                ) : (
                  <span>Generate</span>
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

interface ImageGenerationFormProps {
  setIsGeneratedAt: (value: number) => void
  setIsExtendedVisible: Function
  isExtendedVisible: boolean
}

const ImageGenerationForm: React.FC<ImageGenerationFormProps> = ({
  setIsGeneratedAt,
  setIsExtendedVisible,
  isExtendedVisible,
}) => {
  const imageDescTextareaRef = useRef<any>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [submitImageGeneration] = useGenerateImageMutation()

  const [formState, setFormState] = useState<FormState>({
    prompt: '',
    magicPrompt: 'AUTO',
    aspectRatio: 'ASPECT_1_1',
    visibility: 'private',
    style: 'None',
    aesthetic: 'None',
    mood_and_lighting: 'None',
    type: 'GENERAL',
  })

  useEffect(() => {
    if (imageDescTextareaRef.current) {
      imageDescTextareaRef.current.style.height = '0px'
      const scrollHeight = imageDescTextareaRef.current.scrollHeight
      imageDescTextareaRef.current.style.height = scrollHeight + 'px'
    }
  }, [formState.prompt])

  const handleGenerate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    try {
      await submitImageGeneration(formState)
        .unwrap()
        .then((data) => {
          toast.success(`Image generated successfully`)
        })
    } catch (error: any) {
      if (error.status === 400) {
        toast.error(error.data.message)
        return
      }
      if (error.status === 405) {
        toast.error(error.data.message)
        return
      }
      toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
    } finally {
      setIsLoading(false)
      setIsGeneratedAt(Date.now())
    }
  }

  // const handleClickOutside = (event:any) => {
  //   if (imageDescTextareaRef.current && !imageDescTextareaRef.current.contains(event.target)) {
  //     setIsExtendedVisible(false);
  //   }
  // };

  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formRef.current) {
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <Form className='w-100' onSubmit={handleGenerate}>
      <h3 className={`${classes.topBlock__title} mb-3`}>Start Creating</h3>

      {!isExtendedVisible && (
        <ImageDescToggle prompt={formState.prompt} onClick={() => setIsExtendedVisible(true)} />
      )}
      {isExtendedVisible && (
        <ImageGenerationFormExpanded
          formState={formState}
          setFormState={setFormState}
          imageDescTextareaRef={imageDescTextareaRef}
          setIsExtendedVisible={setIsExtendedVisible}
          isLoading={isLoading}
          className='z-1'
        />
      )}
    </Form>
  )
}

export default ImageGenerationForm
