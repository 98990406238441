
// Test Mode
export const TopUpPlansDataLocal = {
  words_and_credits: [
    {
      name: 'Tier 1',
      full_name: 'TopUp WC Tier 1',
      value: 100000,
      label: '100,000 words + 1000 credits - $50',
      payment_link: 'https://buy.stripe.com/test_fZedRVbjd6ul4AEaF4',
      payment_link_id: 'plink_1Qc5YKI7nKZV6RR2xGSrezwD',
    },
    {
      name: 'Tier 2',
      full_name: 'TopUp WC Tier 2',
      value: 200000,
      label: '200,000 words + 2000 credits - $100',
      payment_link: 'https://buy.stripe.com/test_4gwcNR1ID9Gx9UY3ci',
      payment_link_id: 'plink_1QP2Z7I7nKZV6RR2ghcRSvn1',
    },
    {
      name: 'Tier 3',
      full_name: 'TopUp WC Tier 3',
      value: 300000,
      label: '300,000 words + 3000 credits - $150',
      payment_link: 'https://buy.stripe.com/test_6oE1595YTaKBaZ25kr',
      payment_link_id: 'plink_1QP2uDI7nKZV6RR24EC2IfMr',
    },
    {
      name: 'Tier 4',
      full_name: 'TopUp WC Tier 4',
      value: 400000,
      label: '400,000 words + 4000 credits - $200',
      payment_link: 'https://buy.stripe.com/test_aEU5lpaf97ypd7aaEM',
      payment_link_id: 'plink_1QP1usI7nKZV6RR2B2iylqwy',
    },
    {
      name: 'Tier 5',
      full_name: 'TopUp WC Tier 5',
      value: 500000,
      label: '500,000 words + 5000 credits - $250',
      payment_link: 'https://buy.stripe.com/test_00gg033QLaKB0ko009',
      payment_link_id: 'plink_1QP1vbI7nKZV6RR2irpLhrb9',
    },
    {
      name: 'Tier 6',
      full_name: 'TopUp WC Tier 6',
      value: 600000,
      label: '600,000 words + 6000 credits - $300',
      payment_link: 'https://buy.stripe.com/test_3cs29dfztdWN3wAbIS',
      payment_link_id: 'plink_1QP1wSI7nKZV6RR2uFgVriol',
    },
    {
      name: 'Tier 7',
      full_name: 'TopUp WC Tier 7',
      value: 700000,
      label: '700,000 words + 7000 credits - $350',
      payment_link: 'https://buy.stripe.com/test_aEU159bjdbOFfficMX',
      payment_link_id: 'plink_1QP1xAI7nKZV6RR2wGfs2z8b',
    },
    {
      name: 'Tier 8',
      full_name: 'TopUp WC Tier 8',
      value: 800000,
      label: '800,000 words + 8000 credits - $400',
      payment_link: 'https://buy.stripe.com/test_aEUeVZcnh6ulebe3co',
      payment_link_id: 'plink_1QP1xjI7nKZV6RR2xruYZv3C',
    },
    {
      name: 'Tier 9',
      full_name: 'TopUp WC Tier 9',
      value: 900000,
      label: '900,000 words + 9000 credits - $450',
      payment_link: 'https://buy.stripe.com/test_cN2cNRevp8Ct2sw14h',
      payment_link_id: 'plink_1QP1yJI7nKZV6RR23eWK17t1',
    },
    {
      name: 'Tier 10',
      full_name: 'TopUp WC Tier 10',
      value: 1000000,
      label: '1,000,000 words + 10,000 credits - $500',
      payment_link: 'https://buy.stripe.com/test_00gdRV8715qh0kofZc',
      payment_link_id: 'plink_1QP1yoI7nKZV6RR2n0EaLDNO',
    },
  ],
  images: [
    {
      name: 'Tier 1',
      full_name: 'TopUp IMG Tier 1',
      value: 100,
      label: '100 images - $30',
      payment_link: 'https://buy.stripe.com/test_fZe29d9b56ul8QU8wL',
      payment_link_id: 'plink_1QP2eDI7nKZV6RR2AWUbxpFi',
    },
    {
      name: 'Tier 2',
      full_name: 'TopUp IMG Tier 2',
      value: 200,
      label: '200 images - $60',
      payment_link: 'https://buy.stripe.com/test_eVa29dfztcSJd7a00g',
      payment_link_id: 'plink_1QP2eyI7nKZV6RR2K5Ms9JRI',
    },
    {
      name: 'Tier 3',
      full_name: 'TopUp IMG Tier 3',
      value: 300,
      label: '300 images - $90',
      payment_link: 'https://buy.stripe.com/test_eVa3dhcnhf0R8QU00h',
      payment_link_id: 'plink_1QP2fhI7nKZV6RR2nlu1pQxg',
    },
    {
      name: 'Tier 4',
      full_name: 'TopUp IMG Tier 4',
      value: 400,
      label: '400 images - $120',
      payment_link: 'https://buy.stripe.com/test_3cs3dh871cSJ0koaEW',
      payment_link_id: 'plink_1QP2gOI7nKZV6RR2GAx12UQC',
    },
    {
      name: 'Tier 5',
      full_name: 'TopUp IMG Tier 5',
      value: 500,
      label: '500 images - $150',
      payment_link: 'https://buy.stripe.com/test_28oeVZ3QLcSJffi14n',
      payment_link_id: 'plink_1QP2gnI7nKZV6RR2S5HuJI7c',
    },
    {
      name: 'Tier 6',
      full_name: 'TopUp IMG Tier 6',
      value: 600,
      label: '600 images - $180',
      payment_link: 'https://buy.stripe.com/test_eVabJN2MHg4V2swcN6',
      payment_link_id: 'plink_1QP2hJI7nKZV6RR2tcMWJIGR',
    },
    {
      name: 'Tier 7',
      full_name: 'TopUp IMG Tier 7',
      value: 700,
      label: '700 images - $210',
      payment_link: 'https://buy.stripe.com/test_eVa8xB2MH5qh3wAbJ3',
      payment_link_id: 'plink_1QP2hrI7nKZV6RR25FXiUgKD',
    },
    {
      name: 'Tier 8',
      full_name: 'TopUp IMG Tier 8',
      value: 800,
      label: '800 images - $240',
      payment_link: 'https://buy.stripe.com/test_6oE3dhevp6uld7adRc',
      payment_link_id: 'plink_1QP2iMI7nKZV6RR2KOOwREcW',
    },
    {
      name: 'Tier 9',
      full_name: 'TopUp IMG Tier 9',
      value: 900,
      label: '900 images - $270',
      payment_link: 'https://buy.stripe.com/test_cN2bJNdrlf0RffieVh',
      payment_link_id: 'plink_1QP2jNI7nKZV6RR2bWDsRvar',
    },
    {
      name: 'Tier 10',
      full_name: 'TopUp IMG Tier 10',
      value: 1000,
      label: '1000 images - $300',
      payment_link: 'https://buy.stripe.com/test_8wMdRVaf96ul8QUbJ6',
      payment_link_id: 'plink_1QP2kBI7nKZV6RR2mimXxfSd',
    },
  ],
}


//Live Mode
export const TopUpPlansDataProduction = {
  words_and_credits: [
    {
      name: 'Tier 1',
      full_name: 'TopUp WC Tier 1',
      value: 100000,
      label: '100,000 words + 1000 credits - $50',
      payment_link: 'https://buy.stripe.com/eVacNiaoBcF0dPi8wW',
      payment_link_id: 'plink_1QcNwBI7nKZV6RR2AWpCABdQ',
    },
    {
      name: 'Tier 2',
      full_name: 'TopUp WC Tier 2',
      value: 200000,
      label: '200,000 words + 2000 credits - $100',
      payment_link: 'https://buy.stripe.com/00g9B640dawS3aEcNa',
      payment_link_id: 'plink_1Qc1NgI7nKZV6RR2ItDS5rBB',
    },
    {
      name: 'Tier 3',
      full_name: 'TopUp WC Tier 3',
      value: 300000,
      label: '300,000 words + 3000 credits - $150',
      payment_link: 'https://buy.stripe.com/4gwbJeeERawSeTm8wT',
      payment_link_id: 'plink_1Qc1NeI7nKZV6RR2pJR2fR2e',
    },
    {
      name: 'Tier 4',
      full_name: 'TopUp WC Tier 4',
      value: 400000,
      label: '400,000 words + 4000 credits - $200',
      payment_link: 'https://buy.stripe.com/00gfZu9kxfRc8uY14q',
      payment_link_id: 'plink_1Qc1NcI7nKZV6RR2X7HIhzR7',
    },
    {
      name: 'Tier 5',
      full_name: 'TopUp WC Tier 5',
      value: 500000,
      label: '500,000 words + 5000 credits - $250',
      payment_link: 'https://buy.stripe.com/4gw00w1S57kG26AdRb',
      payment_link_id: 'plink_1Qc1NXI7nKZV6RR2yyigzA9p',
    },
    {
      name: 'Tier 6',
      full_name: 'TopUp WC Tier 6',
      value: 600000,
      label: '600,000 words + 6000 credits - $300',
      payment_link: 'https://buy.stripe.com/28odRm68l8oK7qU00k',
      payment_link_id: 'plink_1Qc1NTI7nKZV6RR2NZsqhh2V',
    },
    {
      name: 'Tier 7',
      full_name: 'TopUp WC Tier 7',
      value: 700000,
      label: '700,000 words + 7000 credits - $350',
      payment_link: 'https://buy.stripe.com/5kA6oU2W98oKbHadR9',
      payment_link_id: 'plink_1Qc1NRI7nKZV6RR2IPF1FANe',
    },
    {
      name: 'Tier 8',
      full_name: 'TopUp WC Tier 8',
      value: 800000,
      label: '800,000 words + 8000 credits - $400',
      payment_link: 'https://buy.stripe.com/28o7sY40d7kGh1u8wO',
      payment_link_id: 'plink_1Qc1NOI7nKZV6RR2RGsbY1h0',
    },
    {
      name: 'Tier 9',
      full_name: 'TopUp WC Tier 9',
      value: 900000,
      label: '900,000 words + 9000 credits - $450',
      payment_link: 'https://buy.stripe.com/cN2bJe0O1cF09z200h',
      payment_link_id: 'plink_1Qc1NLI7nKZV6RR2iwabP5nS',
    },
    {
      name: 'Tier 10',
      full_name: 'TopUp WC Tier 10',
      value: 1000000,
      label: '1,000,000 words + 10,000 credits - $500',
      payment_link: 'https://buy.stripe.com/bIY8x2eERgVgh1u5kA',
      payment_link_id: 'plink_1Qc1NII7nKZV6RR2pRi9wdQG',
    },
  ],
  images: [
    {
      name: 'Tier 1',
      full_name: 'TopUp IMG Tier 1',
      value: 100,
      label: '100 images - $30',
      payment_link: 'https://buy.stripe.com/28o5kQ0O16gCfXq006',
      payment_link_id: 'plink_1Qc1H5I7nKZV6RR271W5cePS',
    },
    {
      name: 'Tier 2',
      full_name: 'TopUp IMG Tier 2',
      value: 200,
      label: '200 images - $60',
      payment_link: 'https://buy.stripe.com/cN26oU0O120meTm4gn',
      payment_link_id: 'plink_1Qc1LYI7nKZV6RR2tLIA7Qrq',
    },
    {
      name: 'Tier 3',
      full_name: 'TopUp IMG Tier 3',
      value: 300,
      label: '300 images - $90',
      payment_link: 'https://buy.stripe.com/fZe6oUcwJ7kG7qUcMU',
      payment_link_id: 'plink_1Qc1LfI7nKZV6RR2xLx81g9d',
    },
    {
      name: 'Tier 4',
      full_name: 'TopUp IMG Tier 4',
      value: 400,
      label: '400 images - $120',
      payment_link: 'https://buy.stripe.com/aEU14A0O120mcLe9AJ',
      payment_link_id: 'plink_1Qc1LdI7nKZV6RR2KMns61zw',
    },
    {
      name: 'Tier 5',
      full_name: 'TopUp IMG Tier 5',
      value: 500,
      label: '500 images - $150',
      payment_link: 'https://buy.stripe.com/aEUeVqdAN34q7qUeV4',
      payment_link_id: 'plink_1Qc1LiI7nKZV6RR2CVezVA5T',
    },
    {
      name: 'Tier 6',
      full_name: 'TopUp IMG Tier 6',
      value: 600,
      label: '600 images - $180',
      payment_link: 'https://buy.stripe.com/14kbJeaoBdJ4cLeaEP',
      payment_link_id: 'plink_1Qc1LkI7nKZV6RR2evHYxcXW',
    },
    {
      name: 'Tier 7',
      full_name: 'TopUp IMG Tier 7',
      value: 700,
      label: '700 images - $210',
      payment_link: 'https://buy.stripe.com/9AQbJegMZcF09z28wI',
      payment_link_id: 'plink_1Qc1LlI7nKZV6RR2BS3e6uWI',
    },
    {
      name: 'Tier 8',
      full_name: 'TopUp IMG Tier 8',
      value: 800,
      label: '800 images - $240',
      payment_link: 'https://buy.stripe.com/eVabJedANgVgcLe28l',
      payment_link_id: 'plink_1Qc1LnI7nKZV6RR24CAGihnC',
    },
    {
      name: 'Tier 9',
      full_name: 'TopUp IMG Tier 9',
      value: 900,
      label: '900 images - $270',
      payment_link: 'https://buy.stripe.com/8wM4gMcwJ20mbHa7sG',
      payment_link_id: 'plink_1Qc1LpI7nKZV6RR2gNlzY3E6',
    },
    {
      name: 'Tier 10',
      full_name: 'TopUp IMG Tier 10',
      value: 1000,
      label: '1000 images - $300',
      payment_link: 'https://buy.stripe.com/3cs5kQeER20m7qUdR5',
      payment_link_id: 'plink_1Qc1LrI7nKZV6RR2QDSrUVh1',
    },
  ],
}