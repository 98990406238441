import {Dropdown, Table} from 'react-bootstrap'
import {useGetTopupsQuery} from '../../../../../../services/settingServiceApi'
import {BsThreeDotsVertical} from 'react-icons/bs'

import ReferralMemberClasses from '../../../ReferralMembersArea.module.scss'
import {useState} from 'react'
import {getDateFormat} from '../../../../../../utils/getDateFormat'
import AddNoteModal from './AddNoteModal'
import ConfirmationModal from '../../../../modal-page/ConfirmationModal'
import axios from 'axios'
import {useAuth} from '../../../../../../app/modules/auth'
import {toast} from 'react-toastify'

interface SidebarProps {
  setActiveCell: (title: string) => void
}

export const TopUpHistoryComponent: React.FC<SidebarProps> = ({setActiveCell}) => {
  const [page, setPage] = useState(1)
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
  const [existingData, setExistingData] = useState<undefined | Array<any>>(undefined)
  const [publicId, setPublicId] = useState('')
  const {data: history, isLoading, refetch} = useGetTopupsQuery({page: page, limit: 10})

  const handlePagination = (action: string) => {
    if (action === 'next') {
      setPage((prev) => prev + 1)
    } else {
      setPage((prev) => prev - 1)
    }
  }

  const handleNote = (publicId: string) => {
    setIsNoteModalVisible(true)
    const data = history.data.find((item: any) => item.public_id === publicId)
    if (data) {
      setExistingData(data)
    }
  }

  const handleUnsubscribe = (publicId: string) => {
    setIsConfirmModalVisible(true)
    setPublicId(publicId)
  }

  const handleCancel = () => {
    setIsConfirmModalVisible(false)
  }

  const handleConfirm = () => {
    setIsConfirmModalVisible(false)
    handleCancelSubscription()
    // Navigate to stripe page for unsubscribe
    // get the link as we need to get the tier and their link
    // window.open(`https://dashboard.stripe.com/test/subscriptions/${publicId}`, '_blank')
  }

  const {currentUser} = useAuth()

  const handleCancelSubscription = () => {
    const API_URL = process.env.REACT_APP_API_URL

    const data = history.data.find((item: any) => item.public_id === publicId)

    axios
      .post(`${API_URL}/payment/cancel-topup`, {
        public_id: data?.public_id,
      })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message)
        } else {
          toast.warning(res.data.message)
        }
      })
      .catch((e) => {
        toast.error('Failed to cancel subscription')
      })
      .finally(() => refetch())
  }

  const tableRows = () => {
    return (
      <>
        {history?.data && history.data.length > 0 ? (
          history.data.map((plan: any, key: number) => (
            <tr key={key} className='text-center'>
              <td>{plan.tier_name}</td>
              <td>{getDateFormat(plan.created_at)}</td>
              <td>{getDateFormat(plan.expired_at)}</td>
              <td>{plan.note || ''}</td>
              <td>{plan.status ? 'Active' : 'Inactive'}</td>

              {plan.status ? (
                <td style={{display: 'flex', justifyContent: 'center'}}>
                  <Dropdown>
                    <Dropdown.Toggle variant='primary' id='dropdown-basic'>
                      <BsThreeDotsVertical />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleNote(plan.public_id)}>
                        {plan.note ? 'Update Note' : 'Add Note'}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleUnsubscribe(plan.public_id)}>
                        Unsubscribe
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              ) : (
                <td style={{display: 'flex', justifyContent: 'center'}}>-</td>
              )}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={6} className='text-center'>
              No plans available.
            </td>
          </tr>
        )}
      </>
    )
  }

  return (
    <div className={ReferralMemberClasses.referral_members}>
      <AddNoteModal
        isModalVisible={isNoteModalVisible}
        setModalVisibility={setIsNoteModalVisible}
        existingData={existingData}
        refetch={refetch}
      />

      <ConfirmationModal
        header='Warning'
        message='Are you sure you want to unsubscribe?'
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        show={isConfirmModalVisible}
      />

      <div className='w-100'>
        <Table
          responsive
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0',
          }}
        >
          <thead>
            <tr>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Tier</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Purchase Date</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Expire Date</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Note</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Status</th>
              <th style={{fontWeight: 'normal', textAlign: 'center'}}>Action</th>
            </tr>
          </thead>
          <tbody>{tableRows()}</tbody>
          <tfoot
            style={{
              width: '100%',
            }}
            className='mt-3 mt-md-0'
          >
            <tr>
              <td colSpan={6}>
                <nav className='w-100 d-flex justify-content-center align-items-center mt-3'>
                  <ul className='pagination pagination-lg'>
                    <li className='page-item' onClick={() => handlePagination('prev')}>
                      <button className='page-link' disabled={page === 1}>
                        <span aria-hidden='true'>&laquo;</span>
                      </button>
                    </li>
                    <li className='page-item'>
                      <button className='page-link'>{page}</button>
                    </li>

                    {/* <li>{data?.hasMore}</li> */}
                    <li className='page-item' onClick={() => handlePagination('next')}>
                      <button className='page-link' disabled={history && !history.hasMore}>
                        <span aria-hidden='true'>&raquo;</span>
                      </button>
                    </li>
                  </ul>
                </nav>
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
    </div>
  )
}
