import hrTemplatesData from '../../new-design/data/hr/hrTemplatesData'
import marketingTemplatesData from '../../new-design/data/marketing/marketingTemplatesData'
import salesTemplatesData from '../../new-design/data/sales/salesTemplatesData'

export const findByWorkflowTitle = (title: string) => {

  return [
    ...hrTemplatesData.templates,
    ...salesTemplatesData.templates,
    ...marketingTemplatesData.templates,
  ].find((template) => template.title === title)
}
