import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {GlobalErrorMessagesEnum} from '../../../../types/types'
import {Button, Form, InputGroup, Modal, Row, Spinner} from 'react-bootstrap'
import classes from '../../profile-page/MembersArea.module.scss'
import classes2 from '../../project-page/Project.module.scss'
import hrTemplatesData from '../../../data/hr/hrTemplatesData'
import salesTemplatesData from '../../../data/sales/salesTemplatesData'
import marketingTemplatesData from '../../../data/marketing/marketingTemplatesData'
import Select from 'react-select'
import * as formik from 'formik'
import * as yup from 'yup'
import {
  useAddToProjectMutation,
  useCreateProjectMutation,
} from '../../../../services/ProjectServiceApi'
import handleError from '../../../../utils/handleError'

const ProjectModal = ({
  setShowTrainingModal,
  showTrainingModal,
  refetch,
  setCurrentUuid,
  currentUuid,
}: {
  showTrainingModal: any
  setShowTrainingModal: any
  refetch: any
  setCurrentUuid: Dispatch<SetStateAction<string | null>>
  currentUuid: any
}) => {
  const [workflowName, setWorkflowName] = useState('')
  const [submitProject] = useCreateProjectMutation()
  const [addToProject] = useAddToProjectMutation()
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState([])
  const [name, setName] = useState('')
  const [value, setValue] = useState('')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)

    const handleSuccess = (data: any, action: string) => {
      toast.success(`${action} successfully`)
    }

    if (currentUuid) {
      addToProject({uuid: currentUuid, value: {value}})
        .unwrap()
        .then((data) => handleSuccess(data, 'Data added to project'))
        .catch((e) => handleError(e))
        .finally(() => {
          refetch()
          handleClose()
          setCurrentUuid(null)
          setIsLoading(false)
        })
    } else {
      const projectData = {name, workflow: workflowName}
      submitProject(projectData)
        .unwrap()
        .then((data) => {
          handleClose()
          handleSuccess(data, 'Project created')
        })
        .catch(handleError)
        .finally(() => {
          refetch()
          setIsLoading(false)
        })
    }
  }

  const handleClose = () => {
    setName('')
    setCurrentUuid(null)
    setWorkflowName('')
    setValue('')
    setShowTrainingModal(false)
  }

  const mergedTemplatesTitle = [
    ...hrTemplatesData.templates,
    ...salesTemplatesData.templates,
    ...marketingTemplatesData.templates,
  ].map((template) => ({
    value: template.title,
    label: template.title,
  }))

  const {Formik} = formik

  const schema = yup.object().shape({
    workflow: yup.string().required().min(3).max(100),
    name: yup.string().required().min(3).max(100),
  })

  return (
    <>
      <Modal show={showTrainingModal} onHide={handleClose} centered backdrop='static'>
        <Modal.Header
          style={{
            border: 'none',
            paddingBottom: 0,
          }}
          closeButton
        >
          <Modal.Title>{!currentUuid ? 'Add Project' : 'Add Data to Project'} </Modal.Title>
        </Modal.Header>

        {/* {
          errors && (
            <div className='alert alert-danger' role='alert'>
              <ul>
                {errors.map((error:any, index:any) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          )
        } */}

        <form onSubmit={handleSubmit}>
          <Modal.Body className={`${classes2.addNewProjectWrapper} addProjectWrapper`}>
            {currentUuid ? (
              <>
                <InputGroup
                  className={`my-4 mt-2 d-flex justify-content-between align-items-center ${classes2.nameInputWrapper}`}
                >
                  <label htmlFor='words' className={'mb-2'}>
                    Insert your data <sup className='text-danger'>*</sup>
                  </label>
                  <input
                    name='name'
                    type='text'
                    className='p-3 w-100 name-input-wrapper'
                    placeholder='Insert your data'
                    minLength={3}
                    maxLength={200}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>Please add you data</Form.Control.Feedback>
                </InputGroup>
              </>
            ) : (
              <>
                <InputGroup
                  className={`my-4 mt-2 d-flex justify-content-between align-items-center ${classes2.nameInputWrapper}`}
                >
                  <label htmlFor='words' className={'mb-2'}>
                    Project Name <sup className='text-danger'>*</sup>
                  </label>
                  <input
                    name='name'
                    type='text'
                    className='p-3 w-100 name-input-wrapper'
                    placeholder='Project Name'
                    minLength={3}
                    maxLength={100}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please choose a project name.
                  </Form.Control.Feedback>
                </InputGroup>

                <label htmlFor='words' className={'mb-2'}>
                  Select Workflow
                  <sup className='text-danger'>*</sup>
                </label>

                <Select
                  options={mergedTemplatesTitle}
                  onChange={(e) => setWorkflowName(e ? e.value : '')}
                  className='w-100'
                />
              </>
            )}
          </Modal.Body>
          <Modal.Footer
            style={{
              padding: '1rem',
              borderTop: 'none',
            }}
          >
            <div className={classes.maping__modalSubmitOuter}>
              <button
                type='button'
                className={classes.maping__modalCloseButton}
                onClick={handleClose}
              >
                Close
              </button>
            </div>
            <div className={classes.maping__modalSubmitOuter}>
              <button type='submit' className='btn btn-primary px-4 h-100 py-2'>
                {isLoading && (
                  <Spinner animation='border' role='status' size='sm'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                )}

                {!isLoading && 'Submit'}
              </button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

export default ProjectModal
