import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface SubmitBtnProps {
  isLoading: boolean;
}

const SubmitBtn: React.FC<SubmitBtnProps> = ({ isLoading }) => {
  return (
    <Button
      type="submit"
      className="universalButton"
      style={{ width: 'unset' }}
      disabled={isLoading}
    >
      {isLoading ? (
        <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
      ) : (
        'Submit'
      )}
    </Button>
  );
};

export default SubmitBtn;
