import React, {FC, useEffect, useState} from 'react'
import classes from './BulkRunTableHeader.module.scss'
import {Form} from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../../../store/store'
import {  BsArrowDown, BsArrowUp, BsSortAlphaDown, BsSortAlphaUp, BsSortDown, BsSortUp } from 'react-icons/bs';
import { setSortingDirection } from '../../../../store/bulk-run/bulkRunSlice';

interface BulkRunTableHeaderProps {
  templateItem: any
}

const BulkRunTableHeader: FC<BulkRunTableHeaderProps> = ({templateItem}) => {
  const [isChecked, setIsChecked] = useState(false)

    const dispatch = useAppDispatch();
    const isAllRowsSelected = useAppSelector((state) => state.bulkRun.selectAllCheckbox);
    const sortingDirection = useAppSelector((state) => state.bulkRun.sortingDirection); // Access sortingDirection from the Redux store

    const handleSortChange = () => {
      // Toggle the sorting direction: if it's 'ASC', change to 'DESC', and vice versa
      const newSortingDirection = sortingDirection === 'ASC' ? 'DESC' : sortingDirection === 'DESC' ? 'ASC' : 'ASC';
      dispatch(setSortingDirection(newSortingDirection)); // Dispatch the action to update the sorting direction
    };

    useEffect(() => {
      setIsChecked(isAllRowsSelected)

      const checkboxes = document.querySelectorAll('td input[type="checkbox"]');
      checkboxes.forEach((checkbox: any) => {
        checkbox.checked = isAllRowsSelected;
      });
    }, [isAllRowsSelected]);


  const handleCheckboxChange = (e: any) => {
    const isCheckedNow = e.target.checked
    setIsChecked(isCheckedNow)

    const checkboxes = document.querySelectorAll('td input[type="checkbox"]')

    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = isCheckedNow
    })
  }

  const {requiredQuestions, additionalQuestions, optionalSelect, imgGenerate, formType} =
    templateItem

  const requiredQuestionsHeaders = requiredQuestions.map((item: any, index: number) => (
    <th key={index} className={classes.thead__questionHeader}>
      {item.title} <span className={classes.required}>*</span>
    </th>
  ))

  const additionalQuestionsHeaders = additionalQuestions.map((item: any, index: number) => (
    <th key={index} className={classes.thead__questionHeader}>
      {item.title}
    </th>
  ))

  const optionalSelectHeaders = optionalSelect.map((item: any, index: number) => (
    <th key={index} className={classes.thead__selectHeader} style={{width: '11%'}}>
      {item.title}
    </th>
  ))

  const isScreeningWorkflow = window.location.href.includes('description-comparer-custom') || window.location.href.includes('screen-custom');

  return (
    <thead className={classes.thead}>
      <tr>
        <th className={'align-middle'} style={{width: '20px'}} id='table-checkbox-header'>
          <Form.Check>
            <Form.Check.Input
              isValid
              type={'checkbox'}
              onChange={handleCheckboxChange}
              checked={isChecked}
            />
          </Form.Check>
        </th>

        <th style={{width: '5%'}}>Run</th>
        {requiredQuestionsHeaders}
        {optionalSelectHeaders}
        <th className={classes.thead__selectHeader} style={{width: '10%'}}>
          Output language
        </th>
        {formType === 'social' && (
          <>
            <th className={classes.thead__selectHeader}>Number of variations</th>
            <th className={classes.thead__selectHeader}>
              Media selector<span className={classes.required}>*</span>
            </th>
          </>
        )}
        {/* {imgGenerate && <th className={classes.thead__checkboxHeader}>Include images</th>} */}
        {additionalQuestionsHeaders}

        {isScreeningWorkflow && (
          <th style={{ width: '10%' }} onClick={handleSortChange}> 
            Rating (1-10)

            {/* {sortingDirection === 'ASC' && <BsSortUp size={24} />}
            {sortingDirection === 'DESC' && <BsSortDown size={24} />} */}
          
          </th>
        )}
        <th style={{width: '20%'}}>Result</th>
        <th style={{width: '8%'}}>Actions</th>
        {/* {imgGenerate && <th className={classes.thead__imagesHeader}>Images</th>} */}
      </tr>
    </thead>
  )
}

export default BulkRunTableHeader
