import type {FC} from 'react'
import classes from './BulkRunTableHeader.module.scss'

interface LeadFinderBulkRunTableHeaderProps {
  templateItem: any
}

const LeadFinderBulkRunTableHeader: FC<LeadFinderBulkRunTableHeaderProps> = ({templateItem}) => {
  const {requiredQuestions, additionalQuestions, optionalSelect, imgGenerate, formType} =
    templateItem

  const requiredQuestionsHeaders = requiredQuestions.map((item: any, index: number) => (
    <th key={index} className={classes.thead__questionHeader} style={{width: '8%'}}>
      {item.title} <span className={classes.required}>*</span>
    </th>
  ))

  const additionalQuestionsHeaders = additionalQuestions.map((item: any, index: number) => (
    <th key={index} className={classes.thead__questionHeader} style={{width: '8%'}}>
      {item.title}
    </th>
  ))

  const optionalSelectHeaders = optionalSelect.map((item: any, index: number) => (
    <th key={index} className={classes.thead__selectHeader} style={{width: '8%'}}>
      {item.title}
    </th>
  ))

  return (
    <thead className={classes.thead}>
      <tr>
        <th className={classes.thead__buttonHeader} style={{width: '6%'}}>
          Run
        </th>
        {requiredQuestionsHeaders}
        <th style={{width: '10%', whiteSpace: 'nowrap'}}>First Name</th>
        <th style={{width: '10%', whiteSpace: 'nowrap'}}>Last Name</th>
        <th style={{width: '10%', whiteSpace: 'nowrap'}}>E-mail</th>
        <th style={{width: '10%', whiteSpace: 'nowrap'}}>E-mail Status</th>
        <th style={{width: '10%', whiteSpace: 'nowrap'}}>E-mail Confidence</th>
        <th style={{width: '10%', whiteSpace: 'nowrap'}}>Company Name</th>
        <th style={{width: '10%', whiteSpace: 'nowrap'}}>Company Size</th>
        <th style={{width: '10%', whiteSpace: 'nowrap'}}>Company Industry</th>
        <th style={{width: '10%', whiteSpace: 'nowrap'}}>Company Website</th>
        <th style={{width: '10%', whiteSpace: 'nowrap'}}>Company location</th>
      </tr>
    </thead>
  )
}

export default LeadFinderBulkRunTableHeader
