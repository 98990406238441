import {useState, type FC, useRef, useEffect} from 'react'
import classes from './BulkRun.module.scss'
import RunIcon from '../../icons/workflow/RunIcon'
import BulkRunForm from './BulkRunForm'
import ExportCSVBtn from './ExportCSVBtn'
import ImportCSVBtn from './ImportCSVBtn'
import {useCheckGenerateLimitMutation} from '../../../../services/gptServiceApi'
import {useAuth} from '../../../../app/modules/auth'
import {LimitNotification} from '../limit-notification/LimitNotification'
import {UniversalNotification} from '../universal-notification/UniversalNotification'
import ImportPDFBtn from './ImportPDFBtn'
import {toast} from 'react-toastify'
import LeadFinderBulkRunForm from './LeadFinderBulkRunForm'
import ExportIcon from '../../icons/workflow/ExportIcon'
import exportClasses from './ExportCSVBtn.module.scss'
import BulkFileImportBtn from './BulkFileImportBtn'
import { Button } from 'react-bootstrap'
import { BsPlus } from 'react-icons/bs'
import axios from 'axios'

interface BulkRunProps {
  title: string
  templateItem: any
}

const LeadFinderBulkRun: FC<BulkRunProps> = ({title = '', templateItem}) => {
  const {auth} = useAuth()
  const [rowsCount, setRowsCount] = useState<any[]>([0])
  const [inputsNames, setInputsNames] = useState<string[]>([])
  const [columnsOrder, setColumnsOrder] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
  const myRef = useRef<HTMLDivElement | null>(null)
  const [triggerRegenerateText, setTriggerRegenerateText] = useState<number | string>('')
  const [triggerRegenerateImg, setTriggerRegenerateImg] = useState<number | string>('')
  const [checkGenerateLimit] = useCheckGenerateLimitMutation()
  const [error, setError] = useState('')
  const [notification, setNotification] = useState('')
  const [currentWorkflowRowsLimit, setCurrentWorkflowRowsLimit] = useState(0)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [fetchData, setFetchData] = useState([])
  const [mappingInputs, setMappingInputs] = useState([])

  const urlParams = new URLSearchParams(window.location.search)
  const bulkRunUuidParam = urlParams.get('uuid')
  const bulkRunHasDataParam = urlParams.get('has-data')
  
  useEffect(() => {
    if (bulkRunHasDataParam) {
      setIsLoading(true)
      axios
        .get(`${process.env.REACT_APP_API_URL}/bulk-run/${bulkRunUuidParam}`)
        .then((res) => {
          if (res.data?.data && res.data.data.length > 0) {
            const rowsCount = res.data.data.length
            setRowsCount([...Array(rowsCount).keys()])
            setFetchData(res.data.data)
          } else {
            console.error('No questions found in response data')
          }
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [])

  const addFormRow = () => {
    const maximumAllowedRows = rowsCount.length + 1
    const isLimitExceeded = currentWorkflowRowsLimit < maximumAllowedRows

    if (isLimitExceeded) {
      toast.warning(maximumRowsToastWarning)
      return
    }
    setRowsCount((prev: any) => [...prev, Date.now()])
  }

  const deleteLast = () => {
    if (rowsCount.length > 1) {
      const newRow = rowsCount.filter((item, index) => index !== rowsCount.length - 1)
      setRowsCount(newRow)
    }
  }

  const maximumRowsToastWarning = () => (
    <div>
      <p>Maximum rows for this workflow: {currentWorkflowRowsLimit}</p>
      <a className='text-primary' href='/upgrade-plan'>
        Click here to upgrade!
      </a>
    </div>
  )

  const checkLimits = async () => {
    const checkRows: any = await checkGenerateLimit({
      limits: {
        words: templateItem.limits.words,
        credits: templateItem.limits.credits ? templateItem.limits.credits : 0,
      },
      rows: 99999,
      token: auth?.access_token,
    })

    const maximumRows = checkRows.error.data.message.replace(/[^0-9]/g, '')
    setCurrentWorkflowRowsLimit(maximumRows)

    // setNotification(
    //   `Maximum rows for this workflow: ${maximumRows}` ||
    //     'An error occured'
    // )
  }

  const getInputsNames = () => {
    const newInputsNames: string[] = []
    let rows = myRef?.current?.querySelectorAll('tr:not(:has(th))')
    const result = [].slice.call(rows).map(function (row: HTMLTableRowElement) {
      const cells = row.querySelectorAll('td')
      return [].slice.call(cells).map((cell: HTMLTableCellElement) => {
        const textArea = cell.querySelector('textarea')
        const select = cell.querySelector('select')
        // const div = cell.querySelector('[class*=text]')
        textArea && newInputsNames.push(textArea.name)
        select && newInputsNames.push(select.name)
      })
    })
    setInputsNames(newInputsNames)
  }

  const handleRunAll = async () => {
    try {
      const checkRows: any = await checkGenerateLimit({
        limits: {
          words: templateItem.limits.words,
          credits: templateItem.limits.credits ? templateItem.limits.credits : 0,
        },
        rows: rowsCount.length,
        token: auth?.access_token,
      })
      if (!checkRows.error) {
        // Resume from where it was left off, without starting from the beginning.
        if (activeIndex != null && activeIndex !== 0) {
          if (activeIndex === rowsCount.length) {
            setActiveIndex(0)
          } else {
            setActiveIndex(activeIndex + 1)
          }
        } else {
          setActiveIndex(0)
        }

        // setActiveIndex(0);
        //setTriggerRegenerateText(Date.now())
        return
      }
      toast.warning(checkRows.error.data.message || 'An error occured')
      //setError(checkRows.error.data.message || 'An error occured')
    } catch (err: any) {}
  }

  const tablesBlock = rowsCount.map((item: number | [], index: number) => {
    return (
      <div key={index} className={classes.bulk__table__item}>
        {
          <LeadFinderBulkRunForm
          rowIndex={index}
          fetchData={fetchData[index]}
          mappingInputs={mappingInputs[index]}
            templateItem={templateItem}
            triggerRegenerateText={triggerRegenerateText}
            triggerRegenerateImg={triggerRegenerateImg}
            csvInputs={item}
            columnsOrder={columnsOrder}
            inputsNames={inputsNames}
            index={index}
            setActiveIndex={setActiveIndex}
            activeIndex={activeIndex}
          />
        }
      </div>
    )
  })

  /* Disable restricted routes */
  const disableImportCSVBtnForNonCSVWorkflows = () => {
    const restrictedRoutes = [
      'pdf-comparer',
      'media-caption',
      'visual-prod-description',
      'screen-custom',
    ]

    return restrictedRoutes.some((route) => window.location.pathname.includes(route))
  }

  useEffect(() => {
    checkLimits()
  }, [currentWorkflowRowsLimit])

  useEffect(() => {
    getInputsNames()
  }, [])

  const headers = [
    'LinkedIn Profile URL',
    'First Name',
    'Last Name',
    'Email',
    'Email Status',
    'Company Name',
    'Company Size',
    'Industry',
    'Website',
    'location',
  ]

  const convertAllTablesToCSV = (): string => {
    const tables = document.querySelectorAll('table')
    const csvData: string[] = []

    // Add headers only once at the beginning
    csvData.push(headers.join(','))

    tables.forEach((table) => {
      const rows = table.querySelectorAll('tbody tr') // Ensure we only get the rows in the tbody

      rows.forEach((row) => {
        const cols = row.querySelectorAll('td:not(.BulkRunForm_generateButton__dlluG)') // Exclude the Run button

        // Only push data rows (skip the header rows)
        if (cols.length > 0) {
          const rowData = Array.from(cols).map((col) => {
            // Check for textarea or span and extract text accordingly
            if (col.querySelector('textarea')) {
              return col.querySelector('textarea')?.value.trim() || ''
            }
            if (col.querySelector('span')) {
              return col.querySelector('span')?.textContent?.trim() || ''
            }
            return col.textContent?.trim() || ''
          })

          // Only push rowData if it matches the expected length (8 columns)
          if (rowData.length === headers.length) {
            csvData.push(rowData.join(','))
          }
        }
      })
    })

    return csvData.join('\n')
  }

  const downloadAllTablesCSV = (): void => {
    const csv = convertAllTablesToCSV()
    if (!csv) return

    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', `bulk_data_${Date.now()}.csv`)
    link.click()
  }

  return (
    <div
      className={`universalPage ${classes.bulk}`}
      style={{
        padding: '.29rem 2.29rem 0.29rem calc(1% + 2.29rem)',
      }}
    >
      <div className='universalTopBlock'>
        <h1 className='universalTopTitle'>Professional Email Finder (Beta)</h1>

        <div className='universalBreadcrumbs'>
          <span>Sales</span>
          <span>/</span>
          <span className='universalBreadcrumbs__lastChild'>Professional Email Finder (Beta)</span>
        </div>
      </div>

      <div className='w-100 d-flex flex-md-row flex-column gap-4 align-items-center justify-content-between'>
        <div className='d-flex align-items-center gap-3'>
          <Button
            className={`${classes.bulk__addBtn} border btn btn-primary`}
            onClick={handleRunAll}
          >
            <RunIcon color='white' /> Run All
          </Button>

          <Button
            onClick={addFormRow}
            className={`${classes.bulk__addBtn} border btn btn-primary`}
          >
            <BsPlus size={24} /> New Row
          </Button>
        </div>

        <div className={`d-flex align-items-center gap-3`}>

          {!disableImportCSVBtnForNonCSVWorkflows() && (
            <ImportCSVBtn
              currentWorkflowRowsLimit={currentWorkflowRowsLimit}
              myRef={myRef}
              setRowsCount={setRowsCount}
              setColumnsOrder={setColumnsOrder}
            />
          )}

          <ExportCSVBtn myRef={myRef} />
        </div>
      </div>

      <div ref={myRef} className={classes.bulk__table}>
        <div className={classes.bulk__table__cont}>{tablesBlock}</div>
      </div>
    </div>
  )
}

export default LeadFinderBulkRun
