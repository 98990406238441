import {gptServiceApi} from './../services/gptServiceApi'
import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import {companyServiceApi} from '../services/companyServiceApi'
import {authServiceApi} from '../services/authServiceApi'
import {mailServiceApi} from '../services/mailServiceApi'
import {userServiceApi} from '../services/userServiceApi'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {apiKeyService} from '../services/apiKeyService'
import {activityServiceApi} from '../services/activityServiceApi'
import {referServiceApi} from '../services/referServiceApi'
import {balanceServiceApi} from '../services/balanceServiceApi'
import {onboardServiceApi} from '../services/onboardSurveyServiceApi'
import {brandVoiceServiceApi} from '../services/brandVoiceServiceApi'
import {projectServiceApi} from '../services/ProjectServiceApi'
import {imageGenerationService} from '../services/imageGenerationService'
import {settingServiceApi} from '../services/settingServiceApi'
import { bulkRunServiceApi } from '../services/bulkRunService'

import bulkRunReducer from './bulk-run/bulkRunSlice';

export const store = configureStore({
  reducer: {
    [authServiceApi.reducerPath]: authServiceApi.reducer,
    [companyServiceApi.reducerPath]: companyServiceApi.reducer,
    [mailServiceApi.reducerPath]: mailServiceApi.reducer,
    [userServiceApi.reducerPath]: userServiceApi.reducer,
    [apiKeyService.reducerPath]: apiKeyService.reducer,
    [projectServiceApi.reducerPath]: projectServiceApi.reducer,
    [gptServiceApi.reducerPath]: gptServiceApi.reducer,
    [activityServiceApi.reducerPath]: activityServiceApi.reducer,
    [onboardServiceApi.reducerPath]: onboardServiceApi.reducer,
    [referServiceApi.reducerPath]: referServiceApi.reducer,
    [balanceServiceApi.reducerPath]: balanceServiceApi.reducer,
    [brandVoiceServiceApi.reducerPath]: brandVoiceServiceApi.reducer,
    [imageGenerationService.reducerPath]: imageGenerationService.reducer,
    [settingServiceApi.reducerPath]: settingServiceApi.reducer,
    [bulkRunServiceApi.reducerPath]: bulkRunServiceApi.reducer,
    bulkRun: bulkRunReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      authServiceApi.middleware,
      companyServiceApi.middleware,
      mailServiceApi.middleware,
      userServiceApi.middleware,
      apiKeyService.middleware,
      projectServiceApi.middleware,
      gptServiceApi.middleware,
      activityServiceApi.middleware,
      onboardServiceApi.middleware,
      referServiceApi.middleware,
      balanceServiceApi.middleware,
      brandVoiceServiceApi.middleware,
      imageGenerationService.middleware,
      settingServiceApi.middleware,
      bulkRunServiceApi.middleware,
    ]),
})
setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
