import {toast} from 'react-toastify'
import {GlobalErrorMessagesEnum} from '../../types/types'

export const catchHandler = (error: any) => {
  if (!error || typeof error !== 'object') {
    toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
    console.error('Unexpected error structure:', error)
    return
  }

  const errorMessages: Record<number, string> = {
    400: error.data?.message?.[0] || 'Invalid Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    429: GlobalErrorMessagesEnum.THROTTLER_EXCEPTION,
    500: GlobalErrorMessagesEnum.SERVER_ERROR,
    503: GlobalErrorMessagesEnum.SERVER_ERROR,
  }

  const message = errorMessages[error.status] || GlobalErrorMessagesEnum.SERVER_ERROR

  toast.error(message)

  if (process.env.NODE_ENV !== 'production') {
    console.error('Error Details:', error)
  }
}
