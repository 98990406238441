import {fetchBaseQuery} from '@reduxjs/toolkit/query'
import type {BaseQueryFn, FetchArgs, FetchBaseQueryError} from '@reduxjs/toolkit/query'
import {AuthModel, useAuth} from '../app/modules/auth'
import { toast } from 'react-toastify'
const API_URL = process.env.REACT_APP_API_URL

const baseQuery = fetchBaseQuery({baseUrl: `${API_URL}/`})
export const refreshBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // Add the authorization to the headers if it exists
  const lsValue: string | null = localStorage.getItem('kt-auth-react-v')
  const token = lsValue ? (JSON.parse(lsValue) as AuthModel) : undefined

  let newArgs: any = args
  newArgs.headers = {
    Authorization: `Bearer ${token?.access_token}`,
  }

  let result = await baseQuery(newArgs, api, extraOptions)

  // if (result.error &&  result.error.status === 429) {
  //   toast.error('Too many requests. Please try again later.')
  // }

  if (result.error && result.error.status === 401) {
    // try to get a new token
    try {
      const lsValue: string | null = localStorage.getItem('kt-auth-react-v')
      const token = lsValue ? (JSON.parse(lsValue) as AuthModel) : undefined
      const refreshResult = await baseQuery(
        {url: 'auth/refresh', headers: {Authorization: `Bearer ${token?.refresh_token}`}},
        api,
        extraOptions
      )

      if (refreshResult.data) {
        // store the new token
        localStorage.setItem('kt-auth-react-v', JSON.stringify(refreshResult.data))

        // retry the initial query
      } else {
      }
    } finally {
      const lsValue: string | null = localStorage.getItem('kt-auth-react-v')
      const token = lsValue ? (JSON.parse(lsValue) as AuthModel) : undefined
      const newArgs: any = args
      newArgs.headers = {
        Authorization: `Bearer ${
          newArgs.url !== 'auth/refresh' ? token?.access_token : token?.refresh_token
        }`,
      }
      result = await baseQuery(newArgs, api, extraOptions)
    }
  }

  return result
}
