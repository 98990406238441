import React, {FC, useState, useCallback, useRef} from 'react'
import axios from 'axios'
import {Modal, Button, Card, ProgressBar, Spinner, Form, Col, Row} from 'react-bootstrap'
import {BsPlusCircleFill, BsFileEarmarkTextFill, BsXCircleFill} from 'react-icons/bs'
import classes from './BulkFileUploadModal.module.scss'
import Input from 'react-select/dist/declarations/src/components/Input'
import {ErrorMessage, Field} from 'formik'
import * as yup from 'yup'
import {toast} from 'react-toastify'
import {GlobalErrorMessagesEnum} from '../../../../../types/types'
import {languages} from '../data/languages'
import {contentLength} from '../data/contentLength'
interface FileUploadStatus {
  id: string
  file: File
  status: 'pending' | 'uploading' | 'completed' | 'failed'
  progress: number
  fileUrl?: string
}

interface BulkFileUploadModalProps {
  isBulkUploadModalVisible: boolean
  handleClose: any
  setMappingInputs: any
  setRowsCount: any
  templateItem: any
}

const BulkFileUploadModal: FC<BulkFileUploadModalProps> = ({
  handleClose,
  isBulkUploadModalVisible,
  setMappingInputs,
  setRowsCount,
  templateItem,
}) => {
  const [uploadStatuses, setUploadStatuses] = useState<FileUploadStatus[]>([])
  const [isDragOver, setIsDragOver] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [requiredInputs, setRequiredInputs] = useState<string[]>([])
  const [optionalSelects, setOptionalSelects] = useState<string[]>([])
  const [outputLanguage, setOutputLanguage] = useState('')

  const updateUploadStatus = useCallback((id: string, updates: Partial<FileUploadStatus>) => {
    setUploadStatuses((prev) =>
      prev.map((status) => (status.id === id ? {...status, ...updates} : status))
    )
  }, [])

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>
  ) => {
    event.preventDefault()
    setIsDragOver(false)

    const files =
      'dataTransfer' in event
        ? Array.from(event.dataTransfer?.files || [])
        : Array.from((event.target as HTMLInputElement).files || [])

    if (!files.length) return

    const newUploadStatuses: FileUploadStatus[] = files.map((file) => ({
      id: `${file.name}-${Date.now()}`,
      file,
      status: 'pending',
      progress: 0,
    }))

    setUploadStatuses((prev) => [...prev, ...newUploadStatuses])

    // Upload files concurrently
    await Promise.all(newUploadStatuses.map(uploadFile))
  }

  const uploadFile = async (uploadStatus: FileUploadStatus) => {
    const {id, file} = uploadStatus

    try {
      setIsLoading(true)
      updateUploadStatus(id, {status: 'uploading'})

      const formData = new FormData()
      formData.append('file', file)

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/aws/upload`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          updateUploadStatus(id, {progress})
        },
      })

      if (response.data.success && response.data.location) {
        updateUploadStatus(id, {
          status: 'completed',
          fileUrl: response.data.location,
          progress: 100,
        })
      } else {
        throw new Error('Upload failed')
      }
    } catch (error) {
      updateUploadStatus(id, {
        status: 'failed',
        progress: 0,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    setIsDragOver(true)
  }

  const handleDragLeave = () => {
    setIsDragOver(false)
  }

  const removeFile = (id: string) => {
    setUploadStatuses((prev) => prev.filter((status) => status.id !== id))
  }

  const openFileInput = () => {
    fileInputRef.current?.click()
  }

  const _handleClose = () => {
    handleClose()
    setUploadStatuses([])
    setRequiredInputs([])
    setOptionalSelects([])
    setOutputLanguage('')
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const dataMapper = uploadStatuses
      .filter((status) => status.status === 'completed')
      .map((status) => {
        const dynamicQuestions: Record<string, string> = {}

        requiredInputs.forEach((input, index) => {
          if (input) {
            dynamicQuestions[`requiredQuestion${index + 1}`] = input
          }
        })

        return {
          optionalSelect1: optionalSelects[0] || 'short',
          language: outputLanguage,
          requiredQuestion1: status.fileUrl,
          ...dynamicQuestions,
        }
      })

    setRowsCount(dataMapper.map((_, i) => i + 1))
    setMappingInputs(dataMapper)
    _handleClose()
  }

  return (
    <Modal show={isBulkUploadModalVisible} onHide={_handleClose} centered size='lg' backdrop='static'>
      <Modal.Header closeButton style={{border: 'none'}}>
        <Modal.Title>Import Bulk Files</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <input
            type='file'
            ref={fileInputRef}
            multiple
            onChange={handleFileChange}
            className='d-none'
          />

          <div
            className={`
            border-dashed rounded p-4 text-center 
            ${isDragOver ? 'border-primary' : 'border-secondary'}
            ${classes.dragDropZone}
          `}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleFileChange}
            onClick={openFileInput}
          >
            <div className='d-flex flex-column align-items-center'>
              <BsPlusCircleFill
                size={48}
                className={`mb-3 ${isDragOver ? 'text-primary' : 'text-muted'}`}
              />
              <p className='mb-2'>
                Drag and drop files here or
                <span className='text-primary mx-1 cursor-pointer'>Browse</span>
              </p>
              <small className='text-muted'>
                {' '}
                {templateItem.title === 'CV Screening'
                  ? 'Supported Formats(.pdf,.doc,.docx)'
                  : 'Supported Formats(.png,.jpg,.jpeg)'}{' '}
              </small>
            </div>
          </div>

          {templateItem.requiredQuestions.length > 0 &&
            templateItem.requiredQuestions.map((item: any, index: number) => {
              const questionId = `requiredQuestion${index + 1}`

              if (index === 0) return null;

              return (
                <Form.Group className='mb-4' controlId={questionId} key={questionId}>
                  <Form.Label>
                    {item.title} <sup className='text-danger'>*</sup>
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    required
                    rows={3}
                    placeholder='Enter Job Description'
                    value={requiredInputs[index] || ''}
                    onChange={(e) => {
                      const newInputs = [...requiredInputs]
                      newInputs[index] = e.target.value
                      setRequiredInputs(newInputs)
                    }}
                  />
                </Form.Group>
              )
            })}

          <Row>
            {templateItem.optionalSelect.length > 0 && (
              <Col md={6}>
                {templateItem.optionalSelect.map((item: any, index: number) => {
                  const questionId = `optionalSelect1${index + 1}`
                  return (
                    <Form.Group className='mb-4' key={index}>
                      <Form.Label>
                        Content Length <sup className='text-danger'>*</sup>
                      </Form.Label>
                      <Form.Control
                        as='select'
                        name={questionId}
                        value={optionalSelects[index] || 'short'}
                        onChange={(e) => {
                          const newInputs = [...requiredInputs]
                          newInputs[index] = e.target.value
                          setOptionalSelects(newInputs)
                        }}
                        className='form-select'
                      >
                        <option value='' disabled>
                          Select Length
                        </option>
                        {contentLength.map((value, i) => (
                          <option key={i} value={value}>
                            {value}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )
                })}
              </Col>
            )}

            <Col md={6}>
              <Form.Group className='mb-4'>
                <Form.Label>
                  Output Language
                </Form.Label>
                <Form.Control
                  as='select'
                  name='language'
                  value={outputLanguage}
                  onChange={(e) => setOutputLanguage(e.target.value)}
                  className='form-select'
                >
                  <option value='' disabled>
                    Select a language
                  </option>
                  {languages.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          {uploadStatuses.length > 0 && (
            <div className={`mt-4 ${classes.upload__container}`}>
              {uploadStatuses.map((status) => (
                <Card key={status.id} className='mb-2'>
                  <Card.Body className='d-flex align-items-center'>
                    <BsFileEarmarkTextFill className='me-3 text-muted' size={24} />
                    <div className='flex-grow-1'>
                      <div className='d-flex justify-content-between mb-1'>
                        <span>{status.file.name}</span>
                        <span>
                          {status.status === 'pending' && 'Pending'}
                          {status.status === 'uploading' && 'Uploading'}
                          {status.status === 'completed' && 'Completed'}
                          {status.status === 'failed' && 'Failed'}
                        </span>
                      </div>
                      <ProgressBar
        
     
                        now={status.progress}
                        variant={
                          status.status === 'completed'
                            ? 'success'
                            : status.status === 'failed'
                            ? 'danger'
                            : 'primary'
                        }
                      />
                    </div>
                    <Button
                      variant='outline-danger'
                      size='sm'
                      className='ms-3'
                      onClick={() => removeFile(status.id)}
                      disabled={status.status === 'uploading'}
                    >
                      <BsXCircleFill size={16} />
                    </Button>
                  </Card.Body>
                </Card>
              ))}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer style={{padding: '1rem', borderTop: 'none'}}>
          <div className={classes.maping__modalSubmitOuter}>
            <button
              type='button'
              className={`mx-3 ${classes.maping__modalCloseButton}`}
              onClick={_handleClose}
            >
              Close
            </button>
            <button type='submit' className={classes.maping__modalCloseButton} disabled={isLoading}>
              {isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'}
            </button>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default BulkFileUploadModal
