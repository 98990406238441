import {useEffect, useState, type FC} from 'react'
import {Button, Form, Table} from 'react-bootstrap'
import {GiHamburgerMenu} from 'react-icons/gi'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../app/modules/auth'
import classes from './RecentActivity.module.scss'
import BulkRun from '../universal-components/bulk-run/BulkRun'
import BulkRunComponent from './components/BulkRunComponent'
import SingleRunComponent from './components/SingleRunComponent'

interface RecentActivityAreaProps {}

const RecentActivityPage: FC<RecentActivityAreaProps> = () => {
  const [switchBulkRun, setSwitchBulkRun] = useState(false)
  const {currentUser, toggleSidebar} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const bulkRunParam = urlParams.get('bulk-run')

    const storedSettings = localStorage.getItem('user-cache-settings')

    if (storedSettings) {
      const parsedSettings = JSON.parse(storedSettings)
      if (parsedSettings.bulkRun !== undefined) {
        setSwitchBulkRun(parsedSettings.bulkRun)
        return;
      }
    }

    if (bulkRunParam === 'true') {
      setSwitchBulkRun(true)
    } else {
      setSwitchBulkRun(false)
    }
  }, [])

  useEffect(() => {
    if (switchBulkRun) {
    }
  }, [switchBulkRun])

  const handleSwitchBulkRun = () => {
    setSwitchBulkRun(!switchBulkRun)
    navigate(`?bulk-run=${!switchBulkRun}`)
    localStorage.setItem('user-cache-settings', JSON.stringify({bulkRun: !switchBulkRun}))
  }

  return (
    <div className={`${classes.recent} universalPage  container-fluid`}>
      <div className='d-flex justify-content-between align-items-center w-100 mb-4'>
        <div>
          <h1 className={classes.recent__title}>Recent Activity</h1>
          <p className={classes.recent__subHeader}>Show All Activity here</p>
        </div>

        <Button variant='dark' className={'navbar-toggler-btn '} onClick={() => toggleSidebar()}>
          <GiHamburgerMenu />
        </Button>

        <div
          className='d-flex align-items-center'
          title={`${switchBulkRun ? 'Disable' : 'Enable'} bulk run`}
        >
          <p style={{fontSize: '1.15rem', color: 'var(--primary-font-color)'}}>Bulk Run</p>

          <Form.Check
            size={100}
            checked={switchBulkRun}
            onChange={handleSwitchBulkRun}
            type='switch'
            className='mx-1 form-control-lg'
          />
        </div>
      </div>

      {
        switchBulkRun ? <BulkRunComponent /> : <SingleRunComponent />
      }

    </div>
  )
}

export default RecentActivityPage
