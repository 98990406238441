import React, {useState} from 'react'
import {Button, Form, Alert, Col, Row} from 'react-bootstrap'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useInformationUpdateOrCreateMutation} from '../../../../../../services/settingServiceApi'
import {toast} from 'react-toastify'
import {GlobalErrorMessagesEnum} from '../../../../../../types/types'
import {useAuth} from '../../../../../../app/modules/auth'
import {CustomTooltip} from '../../../../universal-components/tooltip/Tooltip'
import classes from './../Resellers.module.scss'
import { catchHandler } from '../../../../../../utils/http/catchHandler'
import SubmitBtn from './global/submitBtn'
interface FormData {
  name: string
  title: string
  application_url: string
  description: string
}

const validationSchema = Yup.object({
  name: Yup.string()
    .min(3, 'Name must be at least 3 characters')
    .max(200, 'Name must be at most 200 characters')
    .required('Name is required'),
  // title: Yup.string()
  //   .min(3, 'Title must be at least 3 characters')
  //   .max(200, 'Title must be at most 200 characters')
  //   .required('Title is required'),
  // description: Yup.string()
  //   .min(3, 'Description must be at least 3 characters')
  //   .max(600, 'Description must be at most 600 characters')
  //   .required('Description is required'),
  application_url: Yup.string()
    .url('Application Url must be a valid URL')
    .max(200, 'Application Url must be at most 200 characters')
    .required('Application Url is required'),
})

interface InformationComponentProps {
  data?: FormData
  refetch: () => void
}

const InformationComponent: React.FC<InformationComponentProps> = ({data, refetch}) => {
  const {currentUser} = useAuth()
  const [submit, {isLoading}] = useInformationUpdateOrCreateMutation()
  const [submitStatus, setSubmitStatus] = useState<string | null>(null)

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      title: data?.title || '',
      application_url: data?.application_url || '',
      description: data?.description || '',
      public_id: currentUser?.public_id || '',
    },
    validationSchema,
    onSubmit: async (values) => {


      submit(values)
        .unwrap()
        .then((res) => {
          toast.success('Information updated successfully')
          refetch()
        })
        .catch((e) => {
          catchHandler(e)
        })
    },
  })

  return (
    <>
      <Form onSubmit={formik.handleSubmit} className={classes.form}>
        <Row className='mb-3'>
          <Col md={4}>
            <Form.Group controlId='formName' className='mb-3'>
              <Form.Label>
                Name <sup className='text-danger'>*</sup>
                <CustomTooltip tooltipText='Enter your app name' tooltipAnchor='formName' />
              </Form.Label>
              <Form.Control
                type='text'
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.name && formik.touched.name}
                placeholder='Enter your app name'
              />
              <Form.Control.Feedback type='invalid'>{formik.errors.name}</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group controlId='formTitle' className='mb-3'>
              <Form.Label>
                App Url <sup className='text-danger'>*</sup>
                <CustomTooltip tooltipText='Enter the live app url' tooltipAnchor='formName' />
              </Form.Label>
              <Form.Control
                type='url'
                name='application_url'
                value={formik.values.application_url}
                onChange={formik.handleChange}
                isInvalid={!!formik.errors.application_url && formik.touched.application_url}
                placeholder='Enter the app url (https://app.example.com)'
              />
              <Form.Control.Feedback type='invalid'>
                {formik.errors.application_url}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

<SubmitBtn isLoading={isLoading} />

      </Form>
    </>
  )
}

export default InformationComponent
