import {useEffect, useState, type FC} from 'react'
import {Button, Dropdown, Form, InputGroup, Table} from 'react-bootstrap'
import {
  BsFillPlusCircleFill,
  BsHouseAdd,
  BsPen,
  BsPersonAdd,
  BsPersonLinesFill,
  BsTable,
  BsThreeDotsVertical,
  BsTicketDetailed,
} from 'react-icons/bs'
import {useAuth} from '../../../app/modules/auth'

import {getUsersLimit} from '../../../utils/getUsersLimit'
import InviteColleague from '../homepage/InviteColleague'
import UniversalHiddenScreen from '../universal-components/hidden-screen/UniversalHiddenScreen'
import classes from './Project.module.scss'
import userClasses from '../profile-page/Users.module.scss'
import moment from 'moment'
import {MdDataUsage} from 'react-icons/md'

import ConfirmationModal from '../modal-page/ConfirmationModal'
import {toast} from 'react-toastify'
import {FaFilePen, FaPowerOff, FaRegPenToSquare} from 'react-icons/fa6'
import ExportIcon from '../icons/workflow/ExportIcon'
import {GlobalErrorMessagesEnum} from '../../../types/types'
import {useDeleteProjectMutation, useGetProjectsQuery} from '../../../services/ProjectServiceApi'
import ProjectModal from './components/ProjectModal'
import ProjectIcon from '../icons/navbar/ProjectIcon'
import ProjectDetailsModal from './components/ProjectDetailsModal'
import {GiHamburgerMenu} from 'react-icons/gi'
import {Link, useNavigate} from 'react-router-dom'
import hrTemplatesData from '../../data/hr/hrTemplatesData'
import salesTemplatesData from '../../data/sales/salesTemplatesData'
import marketingTemplatesData from '../../data/marketing/marketingTemplatesData'
import {findByWorkflowTitle} from '../../../utils/Workflows'

interface ProjectPageProps {}

enum ActionType {
  ADD_PROJECT = 'ADD_PROJECT',
  ADD_DATA_TO_PROJECT = 'ADD_DATA_TO_PROJECT',
  EDIT_PROJECT = 'EDIT_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',
}

const ProjectPage: FC<ProjectPageProps> = () => {
  const {currentUser} = useAuth()
  

  const [visibleBackground, setVisibleBackground] = useState(false)
  const [showTrainingModal, setShowTrainingModal] = useState(false)
  const [page, setPage] = useState(1)
  const [currentUuid, setCurrentUuid] = useState<string | null>(null)
  const [actionType, setActionType] = useState<string | null>(null)
  const [confirmationMessage, setConfirmationMessage] = useState<string>('')
  const [keywords, setKeywords] = useState('')
  const [selectedProject, setSelectedProject] = useState<any>(null)
  const [show, setShow] = useState(false)
  const [details, setDetails] = useState<any[]>([])
  const {toggleSidebar} = useAuth()
  const {
    data: {projects = [], hasMore = false} = {},
    isLoading,
    refetch,
  } = useGetProjectsQuery({
    keywords,
    page,
  })

  const [deleteProjectMutation] = useDeleteProjectMutation()

  const navigate = useNavigate();

  const handlePagination = (action: string) => {
    if (action === 'next') {
      setPage((prev) => prev + 1)
    } else {
      setPage((prev) => prev - 1)
    }
  }

  const handleAddProjectAction = () => {
    setSelectedProject(null)
    setActionType(ActionType.ADD_PROJECT)
    setShowTrainingModal(true)
  }

  const handleAddToProject = (uuid: string) => {
    setCurrentUuid(uuid)
    setActionType(ActionType.ADD_DATA_TO_PROJECT)
    setShowTrainingModal(true)
  }

  const handleDeleteAction = (uuid: string) => {
    setCurrentUuid(uuid)
    setActionType(ActionType.DELETE_PROJECT)
    setConfirmationMessage('Are you sure you want to delete this project?')
  }

  const handleCancel = () => {
    setCurrentUuid(null)
  }

  const showDetails = (uuid: string) => {
    const project = projects.find((item: any) => item.uuid === uuid)
    setDetails(project ? project : [])
    setShow(true)
  }

  const handleConfirm = () => {
    const project = projects.find((item: any) => item.uuid === currentUuid)
    const name = project?.name.slice(0, 10)

    if (actionType === ActionType.DELETE_PROJECT) {
      const successMessage = `Project deleted successfully`
      deleteProjectMutation({uuid: currentUuid})
        .unwrap()
        .then(() => {
          toast.success(successMessage)
          setCurrentUuid(null)
        })
        .catch(() => {
          setCurrentUuid(null)
          toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
        })
        .finally(() => {
          refetch()
        })
    }
  }

  const generateBulkRunRoute = (item: any) => {
    const template = findByWorkflowTitle(item.workflow)
    const header = template?.header.toLowerCase().replace(/\s+/g, '-');
    const hasData = item.bulk_run_uuid ? true : false ;

    navigate(`/${header}/${template?.url}/bulk-run?${hasData ? `uuid=${item.bulk_run_uuid}` : `project-uuid=${item.uuid}`}&has-data=${hasData}`);
  }

  const tableRows = projects?.map((item: any = {}) => {
    const {id, name, workflow, created_at, uuid} = item


    return (
      <tr key={id}>
        <td>
          <span className='m-2'>{name}</span>
        </td>

        <td>{workflow}</td>

        <td>
          <p>{moment(created_at).format('MM/DD/YYYY')}</p>
        </td>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              className={classes.dropdownToggle}
              variant='primary'
              id='dropdown-basic'
            >
              <BsThreeDotsVertical />
            </Dropdown.Toggle>
            <Dropdown.Menu className={classes.dropdownMenu}>
              <>
                <Dropdown.Item className={classes.drodownMenuButtons} onClick={()=>{
                  generateBulkRunRoute(item)
                }}>
                <BsTable /> Bulk Run
                </Dropdown.Item>

                {/* <Dropdown.Item
                  className={classes.drodownMenuButtons}
                  onClick={() => handleAddToProject(item.uuid)}
                >
                  <FaRegPenToSquare /> Add
                </Dropdown.Item>
                <Dropdown.Item
                  className={classes.drodownMenuButtons}
                  onClick={() => showDetails(item.uuid)}
                >
                  <BsTicketDetailed /> View
                </Dropdown.Item> */}
                <Dropdown.Item
                  className={classes.drodownMenuButtons}
                  onClick={() => handleDeleteAction(item.uuid)}
                >
                  <MdDataUsage /> Delete
                </Dropdown.Item>
              </>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    )
  })

  return (
    <div className={`${classes.members} universalPage  container-fluid`}>
      <ProjectModal
        showTrainingModal={showTrainingModal}
        setShowTrainingModal={setShowTrainingModal}
        refetch={refetch}
        setCurrentUuid={setCurrentUuid}
        currentUuid={currentUuid}
      />

      <ConfirmationModal
        header='Warning'
        message={confirmationMessage}
        handleCancel={handleCancel}
        handleConfirm={handleConfirm}
        show={
          currentUuid !== null &&
          actionType !== ActionType.EDIT_PROJECT &&
          actionType !== ActionType.ADD_PROJECT &&
          actionType !== ActionType.ADD_DATA_TO_PROJECT
        }
      />

      <ProjectDetailsModal show={show} setShow={setShow} project={details} />

      <div className='w-100 mb-4'>
        <div className='row'>
          <div className='col-lg-4 mb-lg-0 mb-4'>
            <div className={'d-flex  justify-content-between'}>
              <div>
                <h1 className={classes.header}>Projects</h1>
                <p className={classes.subHeader}>Create a project for bulk run</p>
              </div>
              <div>
                <Button
                  variant='dark'
                  className={'navbar-toggler-btn d-lg-none d-block'}
                  onClick={() => toggleSidebar()}
                >
                  <GiHamburgerMenu />
                </Button>
              </div>
            </div>
          </div>
          <div className='col-lg-8'>
            <div
              className={`${userClasses.members__topBlock__btnsBlock} justify-content-lg-end justify-content-between`}
            >
              <button onClick={() => handleAddProjectAction()} title='Feed your personalities'>
                + Add Project
              </button>

              <InputGroup
                style={{
                  width: 'unset',
                  border: '1px solid #282828',
                  background: 'white',
                  color: '#282828',
                  borderRadius: '4px',
                }}
              >
                <Form.Control
                  placeholder='Enter your keywords'
                  aria-label='Enter your keywords'
                  aria-describedby='search-input'
                  onChange={(e) => {
                    setKeywords(e.target.value)
                  }}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>

      <div className='w-100'>
        <Table
          responsive
          style={{
            borderCollapse: 'collapse',
            borderSpacing: '0',
          }}
        >
          <thead style={{backgroundColor: '#f3f3f3'}}>
            <tr>
              <th style={{width: '20%'}}>Name</th>
              <th style={{width: '50%'}}>Workflow</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
        <tfoot
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '22px',
            width: '100%',
          }}
          className='mt-3 mt-md-0'
        >
          <tr>
            <td colSpan={7}>
              <nav className='w-100 bg-light  d-flex justify-content-center p-3'>
                <ul className='pagination pagination-lg'>
                  <li className='page-item' onClick={() => handlePagination('prev')}>
                    <button className='page-link' disabled={page === 1}>
                      <span aria-hidden='true'>&laquo;</span>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button className='page-link'>{page}</button>
                  </li>
                  <li className='page-item' onClick={() => handlePagination('next')}>
                    <button className='page-link' disabled={!hasMore}>
                      <span aria-hidden='true'>&raquo;</span>
                    </button>
                  </li>
                </ul>
              </nav>
            </td>
          </tr>
        </tfoot>
      </div>
    </div>
  )
}

export default ProjectPage
