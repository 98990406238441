import {useEffect, useState, type FC} from 'react'
import classes from './MapingModal.module.scss'
import {languages} from './data/languages'
import {tones} from './data/tones'
import CSVIcon from './Icons/CSVIcon'
import {Form} from 'react-bootstrap'

interface MapingModalProps {
  CSVTableHeaders: string[]
  bulkTableHeaders: string[]
  setColumnsOrder: Function
  setTriggerFormSubmited: Function
  handleVisibility?: Function
  setDescription?: Function
  description?: string
}

const MapingModal: FC<MapingModalProps> = ({
  CSVTableHeaders = [],
  bulkTableHeaders = [],
  setColumnsOrder = () => {},
  setTriggerFormSubmited = () => {},
  handleVisibility = () => {},
  setDescription = () => {},
  description = '',
}) => {
  const [inputs, setInputs] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = +e.target.value
    const columnIndex = +e.target.name
    const newInputs = [...inputs]
    newInputs[columnIndex] = value

    setInputs(newInputs)
  }

  const cancelModal = () => {
    handleVisibility()
  }

  const isLinkedinScreening = window.location.href.includes('description-comparer-custom')

  const handleForm = (e: React.FormEvent) => {
    e.preventDefault()

    // if(isLinkedinScreening) {
    //   inputs[1] = 1
    // }

    setColumnsOrder(inputs)
    setTriggerFormSubmited(Date.now)
    handleVisibility()
  }

  const optionsBlock = CSVTableHeaders.map((item: string, index: number) => {
    return (
      <option key={index} value={index}>
        {item}
      </option>
    )
  })

  const formRows = bulkTableHeaders.map((item: string, index: number) => {
    if (isLinkedinScreening && item === 'Job Description') {
      return null // Skip rendering for 'Job Description' if isLinkedinScreening is true
    }

    if (['Run', 'Result', 'Actions', 'Rating (1-10)'].includes(item)) {
      return null // Skip rendering for these items
    }

    return (
      <label key={index} className={classes.maping__form__row}>
        <h3 className={classes.maping__form__row__title}>{item}</h3>
        {item === 'Tone' ? (
          <select name={index.toString()} value={inputs[index]} onChange={handleChange}>
            {tones.map((tone, toneIndex) => (
              <option key={toneIndex} value={toneIndex}>
                {tone}
              </option>
            ))}
          </select>
        ) : item === 'Output language' ? (
          <select name={index.toString()} value={inputs[index]} onChange={handleChange}>
            {languages.map((language, langIndex) => (
              <option key={langIndex} value={langIndex}>
                {language}
              </option>
            ))}
          </select>
        ) : (
          <select name={index.toString()} value={inputs[index]} onChange={handleChange}>
            {optionsBlock}
          </select>
        )}
      </label>
    )
  })

  return (
    <div className={classes.maping}>
      <h1 className={classes.maping__title}>
        <b>Map CSV Columns</b> &nbsp;
        <span style={{marginLeft: '5px'}}>
          <CSVIcon />
        </span>
      </h1>

      <form className={classes.maping__form} onSubmit={handleForm}>
        {isLinkedinScreening && (
          <Form.Group className='mb-4'>
            <Form.Label>
              <label htmlFor='jobDescription'>
                <h3 className={classes.maping__form__row__title}>
                  Job Description <sup className='text-danger'>*</sup>
                </h3>
              </label>
            </Form.Label>
            <Form.Control
              as='textarea'
              required
              className={`form-control ${classes.maping__form__row__textarea}`}
              id='jobDescription'
              rows={3}
              name='jobDescription'
              placeholder='Enter Job Description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        )}

        {formRows}
        <div style={{display: 'flex', justifyContent: 'end', gap: '1rem'}}>
          <div className={classes.maping__modalSubmitOuter}>
            <button
              onClick={cancelModal}
              type='button'
              className={classes.maping__modalCloseButton}
            >
              Close
            </button>
          </div>
          <div className={classes.maping__modalSubmitOuter}>
            <button type='submit' className={classes.maping__modalSubmitButton}>
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default MapingModal
