import {useEffect, useState, type FC} from 'react'
import classes from './CombinedWorkflow.module.scss'
import {Link, useNavigate} from 'react-router-dom'
import {CustomTooltip} from '../tooltip/Tooltip'
import {useAuth} from '../../../../app/modules/auth'
import {Button, Form} from 'react-bootstrap'
import {GiHamburgerMenu} from 'react-icons/gi'
import {isSuperAdmin} from '../../../../utils/userRoles'

interface CombinedWorkflowProps {
  title: string
  buttonColor: string
  itemsData: any
}

const CombinedWorkflow: FC<CombinedWorkflowProps> = ({title, buttonColor, itemsData}) => {
  const {currentUser, toggleSidebar} = useAuth()
  const [switchBulkRun, setSwitchBulkRun] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const bulkRunParam = urlParams.get('bulk-run')

    const storedSettings = localStorage.getItem('user-cache-settings')
    if (storedSettings) {
      const parsedSettings = JSON.parse(storedSettings)
      if (parsedSettings.bulkRun !== undefined) {
        setSwitchBulkRun(parsedSettings.bulkRun)
        return;
      }
    }

    if (bulkRunParam === 'true') {
      setSwitchBulkRun(true)
    } else {
      setSwitchBulkRun(false)
    }
  }, [])

  const disabledWorkflows = [
    'Lead Finder (Beta)',
    'Marketplace Listing',
    'Image to Marketplace Listing'
    // !isSuperAdmin(currentUser?.email) ? 'Marketplace Listing' : '',
    // !isSuperAdmin(currentUser?.email) ? 'Image to Marketplace Listing' : '',
  ]

  const handleSwitchBulkRun = async () => {
    setSwitchBulkRun(!switchBulkRun)
    navigate(`?bulk-run=${!switchBulkRun}`)
    localStorage.setItem('user-cache-settings', JSON.stringify({bulkRun: !switchBulkRun}))
  }

  const templatesBlock = itemsData.templates
    .slice()
    .sort((a: any, b: any) => a.id - b.id)
    .map((item: any) => {
      const {id, title,header, limits, description, url, Img, tooltipText, tooltipAnchor, notWorking} =
        item

      if (disabledWorkflows.includes(title)) {
        return null
      }

      const getWorkflowUrl = () => {
        if (notWorking || title === 'Lead Finder (Beta)' || title === 'Finance Workflows'
        ) {
          if(!isSuperAdmin(currentUser?.email)){
            return '';
          }
        }

        // For solving issue with bulk as these are not bulk so, don't include bulk.
        if (title === 'Professional Email Finder (Beta)') return url;
        if(header === 'Finance') return url;
        return `${url}${switchBulkRun ? '/bulk-run' : ''}`;
      };

      if (title !== 'Generate Cold Email WITH ICE BREAKER') {
        return (
          <div className='col-12 col-sm-6 col-lg-3 mb-sm-4 mb-3' key={`template-container-${id}`}>
            <div className={classes.combined__row__item}>
              <div className={classes.combined__row__item__topBlock}>
                <h3 className={classes.combined__row__item__topBlock__title}>
                  {title}
                  {notWorking && <span className='universalComingSoon'>Currently unavailable</span>}
                  {tooltipAnchor && tooltipText && (
                    <CustomTooltip tooltipText={tooltipText} tooltipAnchor={tooltipAnchor} />
                  )}
                </h3>
                <p>{description}</p>
              </div>
              <div className={classes.combined__row__item__bottomBlock}>
                <Link
                  to={getWorkflowUrl()} // As Professional Email Finder is already bulk run enabled
                >
                  {
                      (title === 'Lead Finder (Beta)' ) ? (
                        isSuperAdmin(currentUser?.email) ? (
                          <button className='universalButton' style={{ background: buttonColor }}>
                            Try now
                          </button>
                        ) : (
                          <button className='universalButton' style={{ pointerEvents: 'none' }} disabled>
                            Maintenance
                          </button>
                        )
                      ) : (
                        <button className='universalButton' style={{ background: buttonColor }}>
                          Try now
                        </button>
                      )
                    }
                </Link>
              </div>
            </div>
          </div>
        )
      } else if (
        ['danny@klevere.ai', 'sarfaraz@mediusware.com'].includes(currentUser?.email || '')
      ) {
        return (
          <div
            className='p-4 col-12 col-sm-6 col-lg-3'
            style={{height: '240px'}}
            key={`template-special-${id}`}
          >
            <div className={classes.combined__row__item}>
              <div className={classes.combined__row__item__topBlock}>
                <h3 className={classes.combined__row__item__topBlock__title}>
                  {title}
                  {notWorking && <span className='universalComingSoon'>Currently unavailable</span>}
                  {tooltipAnchor && tooltipText && (
                    <CustomTooltip tooltipText={tooltipText} tooltipAnchor={tooltipAnchor} />
                  )}
                </h3>
                <p>{description}</p>
              </div>
              <div className={classes.combined__row__item__bottomBlock}>
                <Link to={notWorking ? '' : url}>
                  <button className='universalButton' style={{background: buttonColor}}>
                    Try now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        )
      }

      return null
    })

  const workflowsBlock = itemsData.workflows.map((item: any) => {
    const {id, title, description, url, Img, tooltipText, tooltipAnchor} = item
    return (
      <div
        className='p-4 col-12 col-sm-6 col-lg-3'
        style={{height: '240px'}}
        key={`workflow-${id}`}
      >
        <div className={classes.combined__row__item}>
          <div className={classes.combined__row__item__topBlock}>
            <h3 className={classes.combined__row__item__topBlock__title}>
              {title}
              {tooltipAnchor && tooltipText && (
                <CustomTooltip tooltipText={tooltipText} tooltipAnchor={tooltipAnchor} />
              )}
            </h3>
            <p>{description}</p>
          </div>
          <div className={classes.combined__row__item__bottomBlock}>
            <Link to={url}>
              <button className='mt-3' style={{background: buttonColor}}>
                Try now
              </button>
            </Link>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className={`universalPage align-items-start d-block ${classes.combined}`}>
      <div className='d-flex justify-content-between w-100 align-items-center mb-sm-4 mb-3'>
        <h1 className='universalTopTitle'>{title} Workflows</h1>
        <Button variant='dark' className='navbar-toggler-btn' onClick={toggleSidebar}>
          <GiHamburgerMenu />
        </Button>
        

        {title !== 'Finance' && (
          <div
            className='d-flex align-items-center'
            title={`${switchBulkRun ? 'Disable' : 'Enable'} bulk run`}
          >
            <p style={{fontSize: '1.15rem', color: 'var(--primary-font-color)'}}>Bulk Run</p>

            <Form.Check
              size={100}
              checked={switchBulkRun}
              onChange={handleSwitchBulkRun}
              type='switch'
              className='mx-1 form-control-lg'
            />
          </div>
        )}
      </div>
      <div className='row'>
        {templatesBlock} {workflowsBlock}
      </div>
    </div>
  )
}

export default CombinedWorkflow
