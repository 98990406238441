import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {toast} from 'react-toastify'
import {useAuth} from '../../../app/modules/auth'
import {useCompleteSurveyMutation} from '../../../services/userServiceApi'
import navData from '../../data/navs/navData'
import countryNames from '../../data/universal-data/countryData'
import professions from '../../data/universal-data/professionData'
import CheckmarkIcon from '../icons/modalform/CheckmarkIcon'
import classes from './ModalPage.module.scss'
import {GlobalErrorMessagesEnum} from '../../../types/types'

interface IFormInputs {
  preferred_workplace: string | undefined
  company_size: string
  how_did_you_hear_about_us: string
  profession: string
  location: string
  userId: number
}

const SurveyModal = () => {
  const navGroup = navData.find((item) => item.groupName === 'workflows')
  const workspaces = navGroup?.navs.filter((item) => item.name !== 'Real Estate')
  const companySize = ['1-10', '11-50', '51-100', '101-500', '501-1000', '>1000']
  const infoSource = ['Google', 'Facebook', 'Linkedin', 'Instagram', 'Twitter', 'Referral', 'Other']
  const checkedRadio = '#2468FF'
  const standartRadio = '#616161'
  const {currentUser, setCurrentUser} = useAuth()
  const formRef = React.useRef<HTMLFormElement>(null)

  const [completeSurvey, {isLoading}] = useCompleteSurveyMutation()
  const [selectedWorkspace, setSelectedWorkspace] = useState<string[]>([])
  const [selectedInfoSource, setSelectedInfoSource] = useState<string>('')
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData(e.currentTarget)
    const formInputs: IFormInputs = {
      preferred_workplace: selectedWorkspace.join(','),
      company_size: formData.get('companySize') as string,
      how_did_you_hear_about_us: formData.get(
        selectedInfoSource === 'Other' ? 'other' : 'infoSource'
      ) as string,
      profession: formData.get('profession') as string,
      location: formData.get('location') as string,
      userId: currentUser?.id as number,
    }
    formRef.current?.reportValidity()
    completeSurvey(formInputs)
      .unwrap()
      .then(() => {
        setCurrentUser((prev: any) => ({...prev, is_survey_completed: true}))
        toast.success('Survey completed')
      })
      .catch((err) => {
        setCurrentUser((prev: any) => ({...prev, is_survey_completed: true}))
        toast.error(GlobalErrorMessagesEnum.SERVER_ERROR)
      })
  }
  const radioInputsBlock = workspaces?.map((item) => {
    const {id, name, Img} = item
    const isWorkspaceSelected = selectedWorkspace.includes(name)
    const toggleWorkspace = (url: string) => {
      if (isWorkspaceSelected) {
        setSelectedWorkspace(selectedWorkspace.filter((item) => item !== url))
      } else {
        setSelectedWorkspace([...selectedWorkspace, url])
      }
    }

    return (
      <label
        key={id}
        className={`${classes.modal__form__group__workspaceBlock__label} ${
          selectedWorkspace.includes(name)
            ? classes.modal__form__group__workspaceBlock__labelActive
            : undefined
        }`}
      >
        <input
          required
          key={id}
          type='radio'
          id={name}
          name='workspace'
          value={name}
          defaultChecked={isWorkspaceSelected}
          onClick={() => toggleWorkspace(name)}
        />
        <Img color={isWorkspaceSelected ? checkedRadio : standartRadio} /> {name}
        <span className={classes.modal__form__group__workspaceBlock__label__checkmark}>
          {isWorkspaceSelected && <CheckmarkIcon />}
        </span>
      </label>
    )
  })

  const companySizeBlock = companySize.map((item, index) => {
    return (
      <option
        key={index}
        value={item}
        style={{
          fontSize: '1.15rem',
        }}
      >
        {item}
      </option>
    )
  })

  const infoSourceBlock = infoSource.map((item, index) => {
    return (
      <option
        key={index}
        value={item}
        style={{
          fontSize: '1.15rem',
        }}
      >
        {item}
      </option>
    )
  })

  const professionBlock = professions.map((item, index) => {
    return (
      <option
        key={index}
        value={item}
        style={{
          fontSize: '1.15rem',
        }}
      >
        {item}
      </option>
    )
  })
  const countryBlock = countryNames.map((item, index) => {
    return (
      <option key={index} value={item}>
        {item}
      </option>
    )
  })
  const errorRef = React.useRef<HTMLParagraphElement>(null)
  const [tabKey, setTabKey] = useState('home')
  const handleSkip = () => {}
  return (
    //@ts-ignore
    <Modal show={true} size='md' centered backdrop='static' scrollable={true}>
      <div
        style={{
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
          padding: '30px',
          overflow: 'auto',
        }}
      >
        <form onSubmit={handleSubmit} ref={formRef}>
          <h3 className={`${classes.modal__form__title} mb-4`}>Welcome to Klevere</h3>
          {tabKey === 'home' ? (
            <div className={classes.modal__form__group}>
              <h4 className={classes.modal__form__group__title}>
                Please choose your preferred workspace
              </h4>
              <div className={classes.modal__form__group__workspaceBlock}>{radioInputsBlock}</div>
              <p ref={errorRef} style={{color: 'red', display: 'none'}}>
                Select a workspace
              </p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginTop: '20px',
                }}
              >
                <div>
                  <button
                    className='btn btn-secondary '
                    onClick={handleSkip}
                    style={{
                      borderRadius: '20px',
                      width: '100px',
                      display: 'none',
                    }}
                  >
                    Skip
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-primary '
                    style={{
                      borderRadius: '20px',
                      width: '100px',
                    }}
                    onClick={() => {
                      if (!selectedWorkspace.length) {
                        errorRef.current!.style.display = 'block'
                        return
                      }
                      setTabKey('profile')
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className={classes.modal__form__group}>
                <h4 className={classes.modal__form__group__title}>What is your profession?</h4>
                <select
                  className='form-select form-select-lg'
                  name='profession'
                  required
                  style={{
                    fontSize: '1.15rem',
                  }}
                >
                  <option
                    value=''
                    style={{
                      fontSize: '1.15rem',
                    }}
                  >
                    Select
                  </option>
                  {professionBlock}
                </select>
              </div>
              <div className={`${classes.modal__form__group} d-none`}>
                <h4 className={classes.modal__form__group__title}>Where are you from?</h4>
                <select className='form-select form-select-lg' name='location'>
                  <option
                    value=''
                    style={{
                      fontSize: '1.15rem',
                    }}
                  >
                    Select
                  </option>
                  {countryBlock}
                </select>
              </div>
              <div className={classes.modal__form__group}>
                <h4 className={classes.modal__form__group__title}>What is your company size?</h4>
                <select
                  className='form-select form-select-lg'
                  name='companySize'
                  required
                  style={{
                    fontSize: '1.15rem',
                  }}
                >
                  <option
                    value=''
                    style={{
                      fontSize: '1.15rem',
                    }}
                  >
                    Select
                  </option>
                  {companySizeBlock}
                </select>
              </div>
              <div
                className={classes.modal__form__group}
                style={{
                  fontSize: '1.15rem',
                }}
              >
                <h4 className={classes.modal__form__group__title}>How did you hear about us?</h4>
                <select
                  className='form-select form-select-lg'
                  name='infoSource'
                  onChange={(e) => setSelectedInfoSource(e.target.value)}
                  required
                  style={{
                    fontSize: '1.15rem',
                  }}
                >
                  <option
                    value={selectedInfoSource}
                    style={{
                      fontSize: '1.15rem',
                    }}
                  >
                    Select
                  </option>
                  {infoSourceBlock}
                </select>
                {selectedInfoSource === 'Other' ? (
                  <input
                    type='text'
                    className='form-control form-control-lg mt-1'
                    placeholder='Enter source'
                    name='other'
                    required
                  />
                ) : null}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginTop: '20px',
                  }}
                >
                  <div>
                    <button
                      className='btn btn-secondary '
                      onClick={() => setTabKey('home')}
                      style={{
                        borderRadius: '20px',
                        width: '100px',
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div>
                    <button
                      className='btn btn-primary '
                      type='submit'
                      disabled={isLoading}
                      style={{
                        borderRadius: '20px',
                        width: '100px',
                      }}
                    >
                      {isLoading ? 'Loading...' : "Let's Go!"}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </form>
      </div>
    </Modal>
  )
}

export default SurveyModal
